.editpayment_cancel_btn {
  background: rgb(245, 245, 245);
  border-radius: 100px;
  padding: 12px;
  cursor: pointer;
  margin-right: 16px;
  width: 140px;
  text-align: center;
}

.editpayment_update {
  width: 220px;
  background: black;
  color: white;
  border-radius: 10px;
  padding: 10px 24px 8px;
}

.main-padding {
  padding: 24px;
}

@media screen and (max-width: 380px) {
  .main-padding {
    padding: 5px;
  }
}
