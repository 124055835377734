.cart-wrapper {
  padding: 0px 0px 120px;
  margin-top: 8px;
}

.cart-wrapper2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.cart-progress-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #274b28;
  height: 80px;
}

.cart-main-conaitner {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  padding: 48px 48px 96px;
}

.cart-main-conaitner .container-inner {
  display: flex;
  justify-content: flex-start;

  width: 100%;
  height: 100%;
  flex-direction: column;
}
.container-inner .top-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.container-inner .cart-pymnt {
  /* height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgb(0 0 0 / 12%);
  border-radius: 8px 8px 0 0;
  max-width: 100%;
  min-height: 136px;
  max-height: 400px;
  padding: 0 24px;
  margin-top: 32px;
  position: relative; */
  min-height: 136px;
  /* display: flex; */
  width: 100%;
  /* border: 1px solid green; */
  position: relative;
  margin-top: 32px;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgb(0 0 0 / 12%);
  border-radius: 8px 8px 0 0;
  padding: 5px 5px 10px 10px;
}
.col1col2-div {
  display: flex;
  flex-direction: row;
  width: 75%;
  justify-content: space-around;
  /* border: 1px solid yellowgreen; */
  min-height: 136px;
  align-items: center;
  padding: 5px;
}
.cart-pymnt .col1 {
  /* display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; */
  /* width: 100%; */
}

.cart-pymnt .col2 {
  width: 60%;
}

.cart-pymnt .col3 {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  min-height: 136px;
  align-items: center;
  margin-right: 5px;
  margin-top: 5px;
}

.cart-main-conaitner .right-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: 320px;
  margin-left: 32px;
}

.right-div .inner-div {
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(39, 75, 40);
  background: rgb(241, 255, 242);
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 25%) 0px 1px 8px;
  padding: 24px;
}

.col1col2-div .pymnt-details {
  background: #f1fff2;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px 19px;
  width: 100%;
  max-width: 408px;
  min-width: 0px;
  display: flex;
  align-items: center;
  height: 100%;
  /* max-height: 80px; */
}

.pymnt-details .item1 {
  display: flex;
  align-items: center;
  width: 100%;
}
.pymnt-details .item2 {
  display: flex;
  align-items: center;
  width: 100%;
}

.pymnt-details .eidt-icon {
  width: 24px;
  height: 24px;
}
.pymnt-details .delete-icon {
  width: 24px;
  height: 24px;
}

.item1 .second-div {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}

.item2 .second-div {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}

.cart-footer {
  position: fixed;
  bottom: 0px;
  padding: 32px;
  height: 80px;
  background: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.cart-footer .p1 {
  margin: 12px 0px;
  font-size: 20px;
  line-height: 130%;
}

.cart-footer .p2 {
  color: rgb(66, 66, 66);
  font-size: 20px;
  margin: 0px 32px 0px 16px;
  line-height: 120%;
}
.text-align-left {
  text-align: left;
}

@media only screen and (max-width: 1000px) {
  body {
    overflow-y: scroll;
  }
  .top-row {
    margin-top: 24px;
    margin-left: 16px;
  }
  .cart-main-conaitner {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cart-main-conaitner .right-div {
    margin-left: 0px;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 913px) {
  body {
    overflow-y: scroll;
  }
  .right-div .inner-div {
    margin-top: 10px;
  }
  .cart-wrapper {
    margin-top: 8px;
  }
}
@media only screen and (max-width: 639px) {
  .footer-div1 .p1 {
    font-size: 13px;
  }
  .footer-div1 .p2 {
    font-size: 13px;
    /* margin: 0; */
  }
}

@media only screen and (max-width: 430px) {
  .footer-div1 .p1 {
    font-size: 13px;
  }
  .footer-div1 .p2 {
    font-size: 13px;
    margin-left: 0;
  }
  .cart-pymnt {
    padding: 16px !important;
  }
  .col1col2-div {
    padding: 0px;
  }
}

@media only screen and (max-width: 717px) {
  .col1col2-div {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .cart-pymnt .col3 {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    min-height: 136px;

    margin-right: 5px;
  }
}

@media only screen and (max-width: 603px) {
  .col1col2-div {
    flex-direction: column;
  }

  .cart-pymnt .col3 {
    display: flex;
    position: absolute;
    top: 12px;
    right: 0;
    min-height: 5px;

    margin-right: 5px;
  }
}
@media only screen and (max-width: 855px) {
  .col1col2-div .pymnt-details {
    padding: 15px 6px;
    width: max-content;
    justify-content: center;
  }
  .cart-pymnt .col2 {
    margin-top: 12px;
    width: 90vw;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 431px) {
  .col1col2-div .pymnt-details {
    padding: 15px 6px;
    width: max-content;
    justify-content: center;
  }
  .cart-pymnt .col2 {
    margin-top: 12px;
    width: 90vw;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 450px) {
  .pymnt-details {
    /* background-color: red !important; */
    flex-direction: column;
    max-height: max-content;
    height: max-content;
    gap: 20px;
    padding: 15px !important;
    width: 90% !important;
  }
  .col1col2-div .pymnt-details {
    padding: 15px 0px;
  }
  .payment-light-circle-icon,
  .eidt-icon {
    width: 30px;
    height: 30px;
  }
  .trash-icon,
  .trash-icon {
    width: 30px;
    height: 30px;
  }
  .col1 {
    line-height: 0;
  }

  .col1 h3 {
    font-size: 12px;
  }
  .col1 p {
    font-size: 10px !important;
  }

  .item2 .second-div {
    margin-left: 0px;
  }

  .item1 .second-div {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 430px) {
  .cart-main-conaitner {
    padding: 10px 10px;
  }
}

@media only screen and (max-width: 290px) {
  /* .cart-footer {
    flex-direction: column;
  } */

  .step2_content_inner-div {
    padding: 4px !important;
  }
}

/* Cart Step 2 */

.step2_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 48px 48px 96px;
}

.step2_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  max-width: 992px;
  /* min-width: 900px; */
  border-radius: 8px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12);
}

.content_header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #f1fff2;
  border-radius: 8px 8px 0px 0px;
  padding: 24px;
}

.step2_content_inner-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff;
  border-radius: 0px 0px 8px 8px;
  padding: 24px;
}

.mt {
  margin-top: 15px;
}

@media only screen and (max-width: 500px) {
  .step2_wrapper {
    padding: 5px;
  }
}

/* step 3 */

.step3_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 48px 48px 96px;
}

.step3_left_div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 0px 96px;
}

.step3_left_div_inner-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.step3_left_div_inner-wrap2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  box-shadow: rgb(0 0 0 / 12%) 0px 1px 8px;
  border-radius: 8px;
}
.step3_left_div_header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background: rgb(241, 255, 242);
  border-radius: 8px 8px 0px 0px;
  padding: 24px;
}
.step3_left_div_content {
  background: rgb(255, 255, 255);
  border-radius: 0px 0px 8px 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 24px 24px 48px;
}
.step3_pay_card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;

  padding: 26px;
  border-radius: 8px;
}
.step3_pay_card_div1 {
  display: flex;
  align-items: center;
  margin: 0px 24px;
}
.step3_pay_card_div2 {
  display: flex;
  align-items: center;
}
.step3_pay_card_btn {
  margin-top: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 100px;
  padding: 16px 32px;
  width: 100%;
  max-width: 300px;
  cursor: pointer;
}

.step3_right_div {
  display: flex;
  flex-direction: column;

  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 12%) 0px 1px 8px;
  padding: 32px;
}

.step3_right_inner_div {
  display: flex;
  flex-direction: column;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 12%) 0px 1px 8px;
  padding: 32px;
}

@media only screen and (max-width: 1300px) {
  .step3_wrapper {
    flex-direction: column;
    padding: 0px;
  }
}

@media only screen and (max-width: 550px) {
  .step3_pay_card_div1 {
    margin: 0;
  }
  .step3_left_div {
    padding: 10px 10px 10px;
  }
  .step3_pay_card {
    padding: 10px;
  }
  .step3_pay_card_btn {
    max-width: 100%;
    justify-content: center;
  }
}

@media only screen and (max-width: 375px) {
  .step3_left_div_content {
    padding: 5px;
  }
}

@media only screen and (max-width: 375px) {
  .step3_pay_card {
    padding: 0px;
  }
}

@media only screen and (max-width: 389px) {
  .step3_right_inner_div {
    padding: 10px;
  }
}

@media only screen and (max-width: 349px) {
  .step3_right_div {
    padding: 10px;
  }
}

@media only screen and (max-width: 300px) {
  .step3_right_div {
    padding: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .cart-progress-bar {
    height: 40px;
    padding: 32px 0px;
  }
  .step3_right_div {
    align-items: center;
    width: 100%;
  }
}
