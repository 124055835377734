

.new-patch-slider-container {
  max-height:100%;
  height: 164px;
  width: 100%;
  position: relative;
  margin: auto;
  overflow: hidden;
  box-shadow:none !important;
}

.new-patch-slider-container .active {
  display: inline-block;
}

.new-patch-slider-container .inactive {
  display: none;
}

.new-patch-slider-container .slides {
  height: 100%;
  width: 100%;
  position: relative;
}

.new-patch-slider-container .slide-image {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}


.new-patch-slider-container .prePatch,
.new-patch-slider-container .nextPatch {
  cursor: pointer;
  z-index: 9;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  height: 32px;
  width: 32px;
  display: flex;
  font-size: 11px;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border-radius: 50%;
}

.new-patch-slider-container .prePatch {
  left: 8px;
}
.new-patch-slider-container .prePatch:hover,
.new-patch-slider-container .nextPatch:hover {
  transition: all 0.5s ease-in;
}

.new-patch-slider-container .nextPatch {
  right: 8px;
}

.new-patch-slider-container .all-dots {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  top: 85%;
  justify-content: center;
  z-index: 9;
}

.new-patch-slider-container .dot {
  cursor: pointer;
  height: 8px;
  width: 8px;
  margin: 0px 4px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: inline-block;
}

.new-patch-slider-container .active-dot,
.new-patch-slider-container .dot:hover {
  background-color: rgba(255, 255, 255, 1);
}
