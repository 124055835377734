.large-slider-container {
  height: 488px;
  width: 100%;
  position: relative;
  margin: auto;
  overflow: hidden;
}

.active {
  display: inline-block;
}

.inactive {
  display: none;
}

.large-slider-container .slides {
  height: 488px;
  width: 100%;
  position: relative;
}

.large-slider-container .sliding-image {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px !important;
}

.large-slider-container .slide-title {
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
  font-weight: 900;
}

.large-slider-container .slide-text {
  font-size: 18px;
  line-height: 23px;
  color: #f1fff2;
  margin-top: 8px;
}

.large-slider-container .prevPatch,
.large-slider-container .nextPatch {
  cursor: pointer;
  z-index: 9;
  position: absolute;
  top: 50%;
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -2rem;
  font-size: 20px;
  border-radius: 50%;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
}

.large-slider-container .prevPatch {
  left: 16px;
}

.large-slider-container .prevPatch:hover,
.large-slider-container .nextPatch:hover {
  transition: all 0.5s ease-in;
}

.large-slider-container .nextPatch {
  right: 16px;
}

.large-slider-container .all-dots {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  top: 90%;
  justify-content: center;
  z-index: 10;
}

.large-slider-container .dot {
  cursor: pointer;
  height: 12px;
  width: 12px;
  margin: 0px 3px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: inline-block;
}

.large-slider-container .active-dot,
.large-slider-container .dot:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.slider-left-sec {
  margin: 32px 0px 0px 32px;
  display: flex;
  flex-direction: column;
}

.slider-right-text {
  margin-top: 32px;
  display: flex;
}
.slider-right-text .btn {
  background: #f1fff2;
  border: 1px solid #274b28;
  border-radius: 100px;
  /* padding: 10px 32px 8px; */
  cursor: pointer;
  text-align: center;
}

.slider_wrapper{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 721px) {
  .slider-left-sec {
    width: 90%;
    display: flex;
    align-items: center;
    margin:24px;
  }
  .slider_wrapper{
    flex-direction: column;
    height: 100%;
  }
  .slider-right-text{
    width: 95%;
    display: flex;
    justify-content: flex-end;
    margin-top: 0px;
    margin-bottom: 32px;

  }
  .large-slider-container{
    max-width: 90%;
  }
}

/* @media only screen and (max-width: 453px) {
  .large-slider-container .slide-title {
    font-size: 16px;
  }
  .large-slider-container .slide-text {
    font-size: 15px;
  }
  .slider-right-text {
    margin-right: 0px !important;
  }

  .slider-right-text {
    padding: 10px 32px 8px;
  }
  .slider-right-text .btn {
    border-radius: 100px;
  }
  .slide-text {
    font-size: 13px;
  }
}

@media only screen and (max-width: 413px) {
  /* .slider-left-sec {
    margin: 0px;
  }
  .slider-left-sec .slide-title {
    line-height: 1;
    font-weight: 100;
  } */
  /* .slider-right-text {
    margin-top: 0px;
    padding: 4px 16px 0px;
  }
  .large-slider-container .slide-text {
    font-size: 13px;
  }
} */ 
