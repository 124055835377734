.map-styles {
  width: 380px;
  height: 496px;
}

@media screen and (max-width: 415px) {
  .map-styles {
    width: 330px;
    height: 496px;
  }
}

@media screen and (max-width: 340px) {
  .map-styles {
    width: 300px;
    height: 496px;
  }
}

@media screen and (max-width: 336px) {
  .map-styles {
    width: 250px;
    height: 496px;
  }
}
