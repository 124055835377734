.login-outer-div {
  min-height: 100vh;
  width: 100vw;
  max-height: 666px;
  background: #ffffff;
  display: flex;
  overflow: hidden;
}

.login-image-div {
  display: flex;
}

.poster-image {
  width: 50vw;
  height: 100%;
  min-height: 100vh;
  object-fit: cover;
}
.logo-div .logo {
  width: 140px;
  height: 56px;
  margin-top: 24px;
  margin-left: 24px;
}

.logo-div {
  position: absolute;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  width: 50vw;
  height: 100%;
  min-height: 100vh;
}

.login-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(255, 255, 255);
  width: 100%;
  max-width: 50%;
  min-width: 50vw;
  margin: 0px;
}

.form-div {
  width: 100%;
  height: 666ox;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid green;
}

.forgot-pwd {
  margin-top: 0.5rem;
  color: #274b28;
  width: 100%;
  font-size: 16px;
  text-decoration: underline;
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
}

@media only screen and (max-width: 735px) {
  .login-image-div {
    display: none;
  }

  .form-div h1 {
    text-align: center !important;
  }
}
@media only screen and (max-width: 383px) {
  .forgot-pwd {
    font-size: 12px;
  }

  .login-input {
    height: 42px !important;
    border-radius: 15px !important;
  }

  .green-button,
  .green-button:hover {
    padding: 24px;

    width: max-content;
    font-size: 16px !important;
  }

  .wspace_4 {
    text-align: left;
  }
}
