/* Active Button */

.update-button,
.update-button:hover {
  border: 0px;
  width: 100%;
  display: inline-block;
  color:"#fff" !important;
  position: relative;
  z-index: 1;
  border: 0px solid #274b28;
  background-color: #274b28;
  text-align: center;
  font-size: 20px;
  line-height: 25px;
  height: 46px;
  border-radius: 100px;
  width: auto;
  box-shadow: none !important;
  padding: 10px 32px 8px;
  /* filter: drop-shadow(0px 6px 13px rgba(0, 0, 0, 0.12)) !important; */
}

.delete-button,
.delete-button:hover {
  border: 0px solid #ff0000 !important;
  background-color: #ff0000 !important;
  border: 0px;
  width: 100%;
  display: inline-block;
  color:"#fff" !important;
  position: relative;
  z-index: 1;
  text-align: center;
  font-size: 20px;
  line-height: 25px;
  height: 46px;
  border-radius: 100px;
  width: auto;
  box-shadow: none !important;
  padding: 10px 32px 8px;
  filter: drop-shadow(0px 6px 13px rgba(0, 0, 0, 0.12)) !important;
}

/* Disable Button */

.update-button-disable,
.update-button-disable:hover {
  border: 0px;
  display: inline-block;
  position: relative;
  z-index: 1;
  background-color: #f5f5f5 !important;
  text-align: center;
  font-size: 20px;
  line-height: 25px;
  height: 46px;
  border-radius: 100px;
  width: auto;
  cursor: not-allowed !important;
  padding: 10px 32px 8px;
  /* filter: drop-shadow(0px 0.5px 4px rgba(0, 0, 0, 0.12)) !important; */
}

.update-button h4,
.delete-button h4 {
  color: #ffffff !important;
}

.update-button-disable h4 {
  color: #757575 !important;
}

@media screen and (max-width: 340px) {
  .update-button {
    width: 150px !important;
    padding: 5px 0px;
  }
}
