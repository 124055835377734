@font-face {
    font-display: swap;
    font-family: CircularStd;
    font-style: normal;
    font-weight: 400;
    src: local("CircularStd Book"),
        local("CircularStd-Book"),
        url(/static/media/CircularStd-Book.393d3ee8.woff2) format("woff2")
}

@font-face {
    font-display: swap;
    font-family: CircularStd;
    font-style: normal;
    font-weight: 700;
    src: local("CircularStd Medium"),
        local("CircularStd-Medium"),
        url(/static/media/CircularStd-Medium.d095a3bd.woff2) format("woff2")
}

@font-face {
    font-display: swap;
    font-family: CircularStd;
    font-style: normal;
    font-weight: 900;
    src: local("CircularStd Black"),
        local("CircularStd-Black"),
        url(/static/media/CircularStd-Black.b61700d0.woff2) format("woff2")
}
.update-form-item {
    width: 100%;
    margin: 0;
    padding: 10px 0 21px;
}

.update-form input {
    background: #FAFAFA !important;
    border: 1px solid #E0E0E0 !important;
    border-radius: 8px;
    height: 56px;
    width: 415px;
    margin-left: 16px;
    padding-left: 24px !important;
    color: #424242 !important;
    font-size: 20px !important;
    line-height: 25px !important;
}

.update-form-select>div {
    background: #FAFAFA !important;
    border: 1px solid #E0E0E0 !important;
    border-radius: 8px !important;
    width: 96px !important;
    height: 56px !important;
    padding: 8px 12px !important;
}

.new-payment-form-select>div {
    background: #FAFAFA !important;
    border: 1px solid #E0E0E0 !important;
    border-radius: 8px !important;
    width: 100% !important;
    height: 56px !important;
    padding: 8px 12px !important;
}

.update-form .ant-select-arrow {
    top: 65% !important;
    right: 18px !important;
}

.settings-form-item {
    width: 100%;
    margin: 0;
    padding: 10px 0 21px;
}

.settings-label {
    color: #9E9E9E !important;
    font-size: 13px !important;
    line-height: 18px !important;
    word-spacing: -4px !important;
}

.settings-label span {
    color: #ff0000;
    font-size: 12px;
    line-height: 16px;
    word-spacing: -4px;
}

.settings-main input, .settings-main input[type="password"] {
    padding: 8px 16px !important;
    color: #424242 !important;
    font-size: 18px !important;
    line-height: 23% !important;
}

.active-icon path {
    fill: #274B28 !important;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
    -webkit-transition: background-color 5000s ease-in-out 0s;
    transition: background-color 5000s ease-in-out 0s;
}

.ant-input-affix-wrapper.ant-input-password>.ant-input#password, .ant-input-affix-wrapper.ant-input-password>.ant-input#confirmPassword {
    background: transparent !important;
}

.password .ant-input-suffix {
    margin-top: 16px !important;
}
/* Active Button */

.update-button,
.update-button:hover {
  border: 0px;
  width: 100%;
  display: inline-block;
  color:"#fff" !important;
  position: relative;
  z-index: 1;
  border: 0px solid #274b28;
  background-color: #274b28;
  text-align: center;
  font-size: 20px;
  line-height: 25px;
  height: 46px;
  border-radius: 100px;
  width: auto;
  box-shadow: none !important;
  padding: 10px 32px 8px;
  /* filter: drop-shadow(0px 6px 13px rgba(0, 0, 0, 0.12)) !important; */
}

.delete-button,
.delete-button:hover {
  border: 0px solid #ff0000 !important;
  background-color: #ff0000 !important;
  border: 0px;
  width: 100%;
  display: inline-block;
  color:"#fff" !important;
  position: relative;
  z-index: 1;
  text-align: center;
  font-size: 20px;
  line-height: 25px;
  height: 46px;
  border-radius: 100px;
  width: auto;
  box-shadow: none !important;
  padding: 10px 32px 8px;
  -webkit-filter: drop-shadow(0px 6px 13px rgba(0, 0, 0, 0.12)) !important;
          filter: drop-shadow(0px 6px 13px rgba(0, 0, 0, 0.12)) !important;
}

/* Disable Button */

.update-button-disable,
.update-button-disable:hover {
  border: 0px;
  display: inline-block;
  position: relative;
  z-index: 1;
  background-color: #f5f5f5 !important;
  text-align: center;
  font-size: 20px;
  line-height: 25px;
  height: 46px;
  border-radius: 100px;
  width: auto;
  cursor: not-allowed !important;
  padding: 10px 32px 8px;
  /* filter: drop-shadow(0px 0.5px 4px rgba(0, 0, 0, 0.12)) !important; */
}

.update-button h4,
.delete-button h4 {
  color: #ffffff !important;
}

.update-button-disable h4 {
  color: #757575 !important;
}

@media screen and (max-width: 340px) {
  .update-button {
    width: 150px !important;
    padding: 5px 0px;
  }
}

.history-table {
  border-collapse: collapse;
  width: 100%;
}

.history-table td,
.history-table th {
  border: none;
}

.history-table tr:hover {
  background-color: #a9f1ab;
}

.history-table th {
  text-align: left;
  background-color: #274b28;
  color: white;
  font-size: 16px;
  line-height: 20px;
  padding: 16px 0px 16px 24px;
}

.history-table td {
  padding: 12px 0px 12px 24px;
}

.modal-width {
  width: 900px !important;
}

.modal-header-div {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 24px;
}

.modal-header-div1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

@media screen and (max-width: 540px) {
  .history-table th {
    padding: 5px;
  }
}

@media screen and (max-width: 674px) {
  .history-table td {
    padding: 5px;
  }
  .history-table td h4 {
    font-size: 14px;
  }

  .history-table td h5 {
    font-size: 12px;
  }
  .history-table th {
    font-size: 10px;
  }
  .modal-header-div {
    padding: 10px;
  }
}

@media screen and (max-width: 318px) {
  .history-table th {
    padding: 0px;
  }

  .history-table td {
    padding: 0px;
  }
  .modal-header-div {
    padding: 5px;
  }
}
@media screen and (max-width: 318px) {
  .history-table th {
    padding: 1px;
    font-size: 8px;
  }
}

.row {
    display: grid;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    width: 100%;
    grid-template-columns: 25% 50% 25%
}

.col {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
}

.col {
    background: #ffffff;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

@media (min-width: 1920px) {
    .col {
        width: calc(100%/5) !important;
        max-width: 425px !important;
        min-width: 424px !important;
    }
}

@media (min-width: 1600px) and (max-width: 1919px) {
    .col {
        width: calc(100%/4) !important;
        max-width: 425px !important;
        min-width: 424px !important;
    }
}

@media (min-width: 1367px) and (max-width: 1599px) {
    .col {
        /* width: calc(100%/3) !important; */
        max-width: 389px !important;
        /* min-width: 388px !important; */
    }
}

@media (min-width:992px) {
    .row {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 24px;
    }
}

@media (min-width:992px) and (max-width: 1366px) {
    .col {
        width: 100%;
        max-width: 327px !important;
    }
}

@media (min-width:768px) and (max-width: 991px) {
    .row {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 24px;
    }
    
}
@media screen and (max-width: 767px) {
    .col {
        max-width: 100%;
        margin: 12px 24px;

    }
}
    

/* Dashboard  */

.statisticsLable {
    font-size: 18px;
    line-height: 26px;
    color: #757575;
    margin: 8px 0 0 !important;
}
.cardTitle{
    font-size: 16px;
    line-height: 20px;
    color: #101112;
}

/* .content-body{
    background-color: red;
    overflow-y: hidden;
}
.content-body::-webkit-scrollbar{
    display: none;
} */


.cardDescription{
    font-size: 14px;
    line-height: 18px;
    color: #757575;
    margin: 4px 0 0 !important;
}

@media only screen and (max-width: 768px) {
   .statisticsLable{
    padding-left: 16px;
   }
   .cardDescription{
    max-width: 185px;
    text-align: left;
   }
  }
.content-div {
  margin: 24px 16px 0;
}

.anticon-caret-down svg {
  height: 16px;
  width: 16px;
}

.small-p {
  font-size: 14px;
  line-height: 18px;
  margin: 0;
}

.green-button,
.green-button:hover {
  background: #274b28 !important;
  border-radius: 100px;
  color: #ffffff !important;
  text-align: center;
  padding: 28px 0;
  max-width: 274px;
  width: 100%;
  font-size: 20px !important;
  line-height: 25px !important;
}

.green-button>div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/* Preloader */

.loader,
.component-loading {
  display: flex !important;
  height: 100% !important;
  width: 100% !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}

.pulse-loader {
  display: contents !important;
}

.pulse-loader>div {
  background: none;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.isMobile{
  display: none;
}

.loader ._loading_overlay_overlay {
  background-color: #ffffff !important;
}

.component-loading ._loading_overlay_overlay {
  background-color: transparent !important;
  width: 100% !important;
}

.loading-text {
  font-size: 18px;
  color: #151515;
  margin: 0 !important;
  line-height: 114%;
}

/* Main content */

/* .content {
  margin: 0;
  min-height: 100vh;
  margin-left: 272px;
  background: #f3f5f3;
  overflow-x: auto;
} */

.content-for-card {
  margin: 0;
  min-height: 100vh;
  max-width: 100%;
  margin-left: 0;
  background: #f3f5f3;
}

/* .content-body {
  padding: 10px;
} */

/* .content-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-top: 96px;
  padding: 24px 32px 96px 32px;
} */

/* Breadcrumb */

.breadcrumb {
  padding-left: 32px;
  margin: 0;
  /* position: fixed; */
  top: 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 5px 5px rgb(0 0 0 / 30%);
  height: 72px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* z-index: 100; */
}

@media only screen and (max-width: 1000px) {
  .breadcrumb {
    padding-left: 100px;
    margin: 0;
    /* position: fixed; */
    top: 0;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 5px 5px rgb(0 0 0 / 30%);
    height: 72px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.breadcrumb-for-cart {
  padding-left: 48px;
  margin: 0;
  /* position: fixed; */
  top: 0;
  width: calc(100%);
  background-color: #ffffff;
  box-shadow: 0 5px 5px rgb(0 0 0 / 30%);
  height: 72px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

/* Sidebar */

.ant-menu-item-active {
  background-color: #274b28 !important;
}

.menu-item {
  margin-left: 0 !important;
  padding-left: "3em" !important;
  height: "4.5em" !important;
  display: "flex" !important;
  align-items: "center" !important;
}

.ant-card-body {
  width: 100% !important;
  display: flex !important;
  padding-bottom: 0px !important;
}

.ant-btn {
  border: none !important;
  box-shadow: none !important;
}

.ant-btn>span {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ant-btn:hover,
.ant-btn:active,
.ant-btn:focus {
  color: #ffffff;
  background-color: #274b28;
  cursor: pointer !important;
  border: none !important;
}

.ant-btn[disabled]:hover {
  cursor: context-menu !important;
}

/* 404 & Support Message */

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  height: 100%;
  min-height: 100vh;
  flex-direction: column;
}

.container_404 {
  background-color: rgba(39, 75, 40, 0.95);
  color: #ffffff;
  height: 356px;
  width: 100%;
  max-width: 576px;
  min-width: 425px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 40px;
}

/* Sign up & Sign in Forms */

.signing-form {
  height: 100vh;
  width: 100vw;
  background-color: #274b28;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn-sm-yellow,
.btn-sm-yellow:hover,
.btn-sm-yellow:active,
.btn-sm-yellow:focus {
  border-radius: 5px;
  background-color: #fed64b !important;
  color: #151515 !important;
  padding: 6px 16px 3px;
  display: flex;
  height: 2.5rem !important;
  justify-content: center;
  align-items: center;
  width: 120px;
}

.btn-sm-black,
.btn-sm-black:active,
.btn-sm-black:focus,
.btn-sm-black:hover {
  border-radius: 5px;
  background-color: #274b28 !important;
  color: #ffffff !important;
  padding: 6px 16px 3px;
  display: flex;
  height: 2.5rem !important;
  justify-content: center;
  align-items: center;
  width: 120px;
}

.btn-lg-black,
.btn-lg-black:active,
.btn-lg-black:focus,
.btn-lg-black:hover {
  margin-top: 2rem;
  border-radius: 5px;
  color: #ffffff;
  background-color: #274b28;
  border-color: rgb(21, 21, 21);
  width: 100%;
  min-height: 3rem !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.btn-back {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  background-color: #274b28;
  color: #ffffff;
  margin-top: 2rem;
  font-size: 15px;
  line-height: 18.97px;
  letter-spacing: 2px;
  border-radius: 10px;
  width: 160px;
  height: 50px;
  cursor: pointer !important;
  border: 2px solid #274b28;
}

.forgot_link,
.forgot_link:hover {
  margin-top: 0.5rem;
  color: #274b28;
  width: 100%;
  font-size: 16px;
  text-decoration: underline;
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
}

.title {
  color: #274b28;
  font-size: 32px;
  line-height: 40px;
}

.black-title {
  color: #010101;
  font-size: 28px;
  line-height: 35.42px;
  text-transform: uppercase;
  margin-top: 1rem;
}

.error-description,
.support-description {
  padding: 0;
  margin: 0;
  color: #010101;
  font-size: 28px;
  line-height: 35.42px;
}

.description {
  color: #010101;
  font-size: 18px;
  line-height: 25.42px;
}

.logout {
  /* position: absolute; */
  bottom: 24px;
  width: 100%;
  color: rgba(255, 255, 255, 0.65) !important;
  text-decoration: none !important;
  cursor: pointer !important;
  margin: 0.3em 0;
  height: 3.5em;
  display: flex;
  align-items: center;
}

.logout:hover {
  width: 80% !important;
  background-color: rgba(0, 0, 0, 0.24) !important;
  color: rgba(255, 255, 255, 1) !important;
  border-radius: 0px 50px 50px 0px !important;
  text-decoration: none !important;
  cursor: pointer !important;
}

/* Dashboard content */

.dropdown-items {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 12px !important;
  margin: 4px 0 !important;
}

.dropdown-items-active,
.dropdown-items:hover {
  background-color: #f1fff2 !important;
  cursor: pointer !important;
}

/* Card View */

/* .patch-card {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  max-width: 100%;
  min-height: 164px;
  max-height: 172px;
  padding: 0 24px 0 0;
  margin-top: 32px;
} */

.patch-details {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  max-width: 100%;
  min-height: 488px;
  max-height: 500px;
  padding: 0;
}

/* .payment-card {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px 8px 0 0;
  max-width: 100%;
  min-height: 136px;
  max-height: 144px;
  padding: 0 24px;
  margin-top: 32px;
} */

/* .payment-details {
  background: #f1fff2;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px 19px;
  width: 100%;
  max-width: 408px;
  min-width: 376px;
  display: flex;
  align-items: center;
  height: 100%;
  max-height: 80px;
} */

.payment-circle-icon {
  background: rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  padding: 12px;
  height: 48px;
  width: 48px;
}

.payment-light-circle-icon {
  border: 1px solid #274b28;
  border-radius: 50%;
  height: 48px;
  width: 48px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overdue {
  background: #f44336;
  color: #ffffff;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 24px;
  /* min-width: 0px; */
}

.in-active {
  background: #e9e647;
  color: #ffffff;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 24px;
}

.within-due {
  background: #fff3e0;
  color: #424242;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
}

/* Search bar styles */

span[aria-label="search"] svg {
  height: 24px;
  width: 24px;
  color: #757575 !important;
}

::-webkit-input-placeholder {
  color: #274b28 !important;
  font-size: 20px !important;
  line-height: 25px !important;
  opacity: 0.8;
  word-spacing: -6px;
}

:-ms-input-placeholder {
  color: #274b28 !important;
  font-size: 20px !important;
  line-height: 25px !important;
  opacity: 0.8;
  word-spacing: -6px;
}

::placeholder {
  color: #274b28 !important;
  font-size: 20px !important;
  line-height: 25px !important;
  opacity: 0.8;
  word-spacing: -6px;
}

/* @media screen and (max-width: 1199px) {
  .search-field {
    max-width: 100%;
  }
} */
@media screen and (max-width: 556px) {
  .search-field {
    margin-left: 6px !important;
    font-size: 15px !important;
    width: 182px !important;
  }

  /* .search-field span {
    padding: 0px !important;
  } */
  .search-field input {
    margin-left: 3px !important;
  }

  .content-div {
    margin: 32px 16px;
  }
}

.search-field {
  display: flex;
  position: relative;
  width: 100%;
  height: 64px;
  /* margin-bottom: 23px; */
  flex-grow: 2;
  min-width: 130px;
}

.search-field input {
  background: none !important;
  word-spacing: 0px !important;
  margin-left: 12px;
  font-size: 20px;
  line-height: 25%;
  color: #274b28 !important;
}

/* Share button */

.share-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
  color: blue;
}

.ant-modal-content {
  border-radius: 8px !important;
}

.trash-icon {
  background: #ffebee;
  border: 1px solid #f44336;
  border-radius: 50%;
  height: 48px;
  width: 48px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal */

.modalTitle {
  height: 72px;
  padding-left: 24px;
  font-weight: 900;
}

.areaForm {
  padding: 32px 24px;
}

.areaForm .areaInputContainer {
  height: 64px;
  position: relative;
  margin-top: 16px;
}

.language-selector .ant-select-selector {
  height: 44px !important;
  border-radius: 5px;
  background: none !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100% !important;
}

.language-selector .ant-select-selection-item {
  font-size: 18px !important;
  line-height: 130% !important;
}

.pointer {
  cursor: pointer;
}

@media only screen and (max-width: 868px) {
  .patch-card-resp {
    flex-direction: column;
    margin-top: 0 !important;
    height: 45px;
    width: 97px !important;
  }
}

@media only screen and (max-width: 868px) {
  .patch-card-resp .text-props {
    width: 119px !important;
    height: 24px !important;
    margin-top: 8px;
  }
}

@media only screen and (max-width: 868px) {
  .patch-card-resp .detail-btn {
    height: 26px !important;
    margin-top: 21px;
  }
}

@media only screen and (max-width: 868px) {
  .patch-card-head {
    margin-left: -1px !important;
    margin-top: -16px !important;
  }
}

.patch-card-wrapper {
  width: 100%;
}

.patch-card {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 5px;

  margin-top: 35px;
  padding: 10px;
}

.patch-card .slider-div {
  width: 280px;
  height: 100%;
}

.patch-card .patch-card-right {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12);
  padding-left: 32px;
  min-width: 0px;
}

.patch-btn1 {
  background: #eefbef;
  border: 1px solid #e0e0e0;
  display: flex;
  flex-direction: row;
  width: 179px;
  height: 45px;
  border-radius: 100px;
  
  justify-content: center;
  align-items: center;
  min-width: 0px;
  margin-right: 5px;
}

.patch-btn2 {
  width: 179px;
  height: 45px;
  background: #fff3e0;
  border: 1px solid #e0e0e0;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 0;
}

.patch-bottom-div {
  display: flex;
  flex-direction: row;
  width: 100%;

  justify-content: space-between;
  align-items: center;

  min-width: 0px;
}

.patch-bottom-left-div {
  display: flex;
  flex-direction: row;
  width: 300px;
  min-width: 0px;
}

.bottom-right-div {
  display: flex;
  flex-direction: row;
  width: 226px;
  height: 44px;
  justify-content: space-around;
  min-width: 0px;
  margin-right: 10px;
}

.details-btn {
  width: 130px;
  height: 44px;
  font-family: "Circular Std";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  background: #f1fff2;

  border: 1px solid #274b28;
  border-radius: 100px;
  justify-content: center;
  flex-grow: 3;
  margin-right: 14px !important;
}

.h3-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  width: 264px;
  color: #274b28;
}

.searchbar-div {
  display: flex;
  width: 100%;
}

.search-btn {
  height: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12);
  background: #274b28;
  padding: 20px 24px;
  /* min-width: 0; */
  color: white;
  margin-left: 10px;
}
@media screen and (max-width: 768px) {
  .patch-card-right{
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
  

@media only screen and (max-width: 1075px) {
  .patch-bottom-div {
    flex-direction: column;
  }

  .patch-card .patch-card-right {
    padding: 10px;
  }

  .bottom-right-div {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 44px;

    min-width: 100px;
  }

  .patch-bottom-left-div {
    display: flex;
    flex-direction: row;
    width: 100%;

    min-width: 200px;
  }

  .details-btn {
    width: 500px;
    height: 44px;
    font-family: "Circular Std";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-align: center;
    background: #f1fff2;

    border: 1px solid #274b28;
    border-radius: 100px;
    justify-content: center;
    margin-right: 7px !important;
  }
}

@media screen and (max-width:786px) {
  .isMobile{
    display:block;
  }
  
}
@media only screen and (max-width: 608px) {
  .patch-card {
    flex-direction: column;
    padding: 5px;
    margin:12px 0px;
  }
  .breadcrumb-for-cart{
    padding: 0px;
  }

  .patch-card .slider-div {
    width: 100%;
  }

  .patch-btn1 {
    height: 44px;
  }

  .patch-btn2 {
    height: 44px;
  }

  .patch-bottom-div {
    margin:8px 0px;
    justify-content: space-around;
  }
}

@media only screen and (max-width: 420px) {
  .search-field {
    padding: 0px;
    height: 42px !important;
  }

  .search-btn {
    /* padding: 0px; */
    height: 42px !important;
    min-width: 0px;
    font-size: 11px;
  }

  .searchbar-div {
    height: 45px;
  }

  .patch-card {
    margin-top: 8px;
  }
}

.layout-mobile {
  height: 100%;
}

@media only screen and (max-width: 995px) {
  .sidebar-responsive {
    position: absolute;
    z-index: 100;
    height: 100%;
  }

  .layout-mobile {
    height: 100vh;
  }

  .within-due {
    padding: 32px 24px;
  }

  .in-active {
    padding: 32px 24px;
  }

  .overdue {
    padding: 32px 24px;
  }
}

.search-field-wrap {
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  border: none;
  box-shadow: rgb(0 0 0 / 12%) 0px 1px 8px;
  padding: 11px 28px;
}

@media only screen and (max-width: 290px) {
  .search-field-wrap {
    padding: 0;
  }
}

@media only screen and (max-width: 378px) {
  .overdue {
    padding: 0px;
  }
}

.para {
  margin: 4px 8px 8px;
}

@media only screen and (max-width: 338px) {
  .overdue p {
    font-size: 13px !important;
  }

  .para {
    font-size: 13px !important;
    margin: 0px;
  }
}

@media only screen and (max-width: 464px) {
  .para {
    font-size: 13px !important;
    margin: 0px;
  }
}

@media only screen and (max-width: 384px) {
  .para {
    font-size: 10px !important;
    margin: 0px;
  }

  .small-p {
    font-size: 10px !important;
    /* max-width: 50px; */
  }
}

@media only screen and (max-width: 312px) {
  .in-active {
    padding: 0px;
  }
}

@media only screen and (max-width: 290px) {
  .payment-card {
    padding: 0;
  }
}

.cart-icon-container1 {
  background: #274b28;
  padding: 16px 32px;
  cursor: pointer;
  display: flex;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;

  align-items: center;
  justify-content: space-between;
}



.cart-icon-container2 {
  background: #f1fff2;
  padding: 23px;
  width: 96px;
  cursor: "pointer";
}

.cart-counter {
  background: rgb(241, 255, 242);
  border-radius: 50%;
  margin: 0px 16px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 380px) {
  .cart-icon-container1 {
    padding: 16px 6px;
  }

  .cart-icon-container2 {
    padding: 23px;
  }

  /* .cart-counter {
    margin: 0px 16px;
  } */

  .breadcrumb {
    padding-left: 60px;
  }
}

.new-patch-breadcrumb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  /* background-color: yellow; */
}

.breadcrumb-text-div {
  display: flex;
}

@media only screen and (max-width: 568px) {
  .breadcrumb {
    padding-left: 63px;
  }


  /* .breadcrumb-text-div {
    display: none;
  } */
}

@media only screen and (max-width: 485px) {
  .breadcrumb-text-div {
    max-width: 60%;
    text-overflow: ellipsis;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .cart-icon-container1 {
    padding: 16px 2px;
  }

  .cart-counter {
    height: 25px;
    width: 25px;
    margin: 0px;
  }
}

@media only screen and (max-width: 485px) {

  .cart-icon-container1 {
    /* width: 30%; */
    height: 100%;
    /* position: relative;
    left: 284px; */
  }
}
.card-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 24px;
}

.large-card-view {
  width: 100%;
  /* max-height: 378px; */
  /* max-width: 454px; */
  margin-top: 15px;
}

.card-view:nth-child(1) {
  margin-right: 24px;
}

.small-card-view {
  width: 100%;
  max-height: 378px;
  max-width: 454px;
}

.card-outer-div {
  display: flex;
  width: 100%;
  margin-top: 16px;
  flex-direction: row;
}

.card-outer-div .card-inner-div-1 {
  width: 100%;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  margin-right: 16px !important;
  min-width: 0px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.card-outer-div .card-iner-div-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #5bcd63;
  min-width: 0px;
  width: 100%;
  padding: 24px 24px 16px;
}

.col3-div-1 {
  display: flex;
  justify-content: space-between !important;
  width: 100%;
}
.temp-p {
  font-size: 12px;
}

.temperature-div {
  display: flex;
  width: 100%;
  margin: 16px 0px;
}
.temp-icon-wrapper {
  background: rgb(227, 242, 253);
  border-radius: 8px;
  min-width: 0px;
  padding: 0px;
  /* margin-top: 23px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media only screen and (max-width: 375px) {
  .temperature-div {
    flex-direction: column;
  }
  .temp-icon-wrapper {
    margin-top: 23px;
  }
}

@media only screen and (max-width: 500px) {
  .temp-p {
    font-size: 10px;
  }
}

@media only screen and (max-width: 450px) {
  .card-view {
    padding: 8px;
  }
}

@media only screen and (max-width: 350px) {
  .card-iner-div-2 {
    padding: 5px !important;
  }
}

/* making cards responsive */

.card-margin {
  margin-left: 16px;
}
.card-padding {
  padding: 12px;
}

@media only screen and (max-width: 336px) {
  .card-margin {
    margin-left: 2px;
  }
  .card-padding {
    padding: 0px;
  }
}

/* temp card */

.temp-img {
  height: 120px;
  width: 120px;
}
.chart-img {
  width: 207px;
  height: 127px;
}

.water-img {
  height: 72px;
  width: 212px;
  overflow-x: hidden;
}
.humidity-img {
  height: 154px;
  width: 154px;
}

@media only screen and (max-width: 390px) {
  .temp-img {
    height: 50px;
    width: 50px;
  }
}

@media only screen and (max-width: 420px) {
  .water-img {
    height: 72px;
    width: 136px;
  }
}

.water-img-container {
  background: rgb(33, 150, 243);
  border-radius: 8px;
  max-width: 175px;
  height: 288px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:  40px 24px;
}

@media only screen and (max-width: 400px) {
  .water-img-container {
    padding: 24px 0px;
  }
  .water-img {
    height: 72px;
    width: 100px;
  }
  .humidity-img {
    height: 154px;
    width: 122px;
  }
}

.selectorBtn {
  background: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  width: auto;
  border-radius: 100px;
  box-shadow: rgb(0 0 0 / 12%) 0px 2px 8px;
  padding: 8px 24px;
  cursor: pointer;
}

@media only screen and (max-width: 390px) {
  .selectorBtn {
    padding: 3px 6px;
  }
  .selectorBtn h4 {
    font-size: 10px;
  }
  .statisticsLable {
    margin: 0px !important;
    font-size: 10px;

    margin-left: 4px !important;
  }
}

/* The container */
.checkbox_container {
    display: block;
    position: relative;
    cursor: pointer;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox_container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
}

/* On mouse-over, add a grey background color */
.checkbox_container:hover input~.checkmark {
    background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox_container input:checked~.checkmark {
    background-color: #274B28;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkbox_container input:checked~.checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkbox_container .checkmark:after {
    left: 7px;
    top: 2px;
    width: 6px;
    height: 14px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.checkbox_link {
    margin: 0 4px;
    color: #2196f3;
}

.checkbox_link:hover {
    text-decoration: underline;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::selection {
  color: #ffffff !important;
  background-color: #274b28 !important;
}

.App {
  min-height: 100%;
  width: 100%;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern";
}

html {
  scroll-behavior: smooth;
}

body {
  -webkit-appearance: none;
  font-family: CircularStd, sans-serif;
  overflow-x: hidden;
}

body,
.ant-card,
.ant-modal,
.ant-table {
  font-variant: none !important;
  font-feature-settings: "kren", "kern", "kern" off !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
  font-weight: normal;
}

h1 {
  font-size: 32px;
  line-height: 40px;
}

h2 {
  font-size: 22px;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  line-height: 24px;
}

h4 {
  font-size: 16px;
  line-height: 23px;
}

h5 {
  font-size: 14px;
  line-height: 20px;
}

h6 {
  font-size: 12px;
  line-height: 18px;
}

p {
  font-size: 16px;
  line-height: 20px;
  margin: 8px 0;
}

.w-100 {
  width: 100%;
}

.wv-100 {
  width: 100vw;
}

.wv-50 {
  width: 50vw;
}

.h-100 {
  height: 100%;
}

.hv-100 {
  height: 100vh;
}

.hv-50 {
  height: 50vh;
}

.mt-32 {
  margin: 32px 0 0;
}

.mt-24 {
  margin: 24px 0 0;
}

.mt-20 {
  margin: 20px 0 0;
}

.mt-16 {
  margin: 16px 0 0;
}

.mt-12 {
  margin: 12px 0 0;
}

.mt-8 {
  margin: 8px 0 0;
}

.padd-16 {
  padding: 16px;
}

.padd-14 {
  padding: 14px;
}

button {
  cursor: pointer !important;
}

span[aria-label="eye-invisible"],
span[aria-label="eye"] {
  color: #000000 !important;
}

.settings-form-item span[aria-label="eye-invisible"],
.settings-form-item span[aria-label="eye"] {
  color: rgba(1, 1, 1, 1) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ant-switch-checked {
  background-color: #274b28 !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}

span[aria-label="caret-up"],
span[aria-label="caret-down"] {
  color: #01010140 !important;
  font-size: 14px !important;
}

.borderEEE {
  border: 1px solid #eeeeee;
}

.br-4 {
  border-radius: 4px;
}

.br-8 {
  border-radius: 8px;
}

.br-12 {
  border-radius: 12px;
}

.br-16 {
  border-radius: 16px;
}

/* General styles */

.flex {
  display: flex;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyCenter {
  justify-content: center;
}

.justifyBetween {
  justify-content: space-between !important;
}

.justifyEnd {
  justify-content: flex-end;
}

.alignStart {
  align-items: flex-start;
}

.alignCenter {
  align-items: center;
}

.alignEnd {
  align-items: flex-end;
}

.textGray {
  color: #757575;
}

.textGreen {
  color: #274b28;
}

.active {
  color: #2196f3;
}

/* Cropper */

.cropper-modal {
  background: #274b28 !important;
  opacity: 0.5 !important;
  border-radius: 8px !important;
}

/* Buy now */

.buyNow {
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1fff2;
  border: 1px solid #274b28;
  border-radius: 100px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.cartIcon {
  margin-left: 16px;
}

.success-toast {
  background-color: #27ae60;
}

.error-toast {
  background-color: #eb5757;
}
.wspace_4 {
  word-spacing: -4px !important;
}

.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 10px;
  right: -57px;
  z-index: 1;
  width: 50px;
  height: 50px;
  color: #274b28;
  font-size: 29px;
  line-height: 42px;
  text-align: center;
  background: transparent;
  border-radius: 100%;
  cursor: pointer;
  transition: background 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-layout-header {
  background: #274b28;
}

.login-outer-div {
  min-height: 100vh;
  width: 100vw;
  max-height: 666px;
  background: #ffffff;
  display: flex;
  overflow: hidden;
}

.login-image-div {
  display: flex;
}

.poster-image {
  width: 50vw;
  height: 100%;
  min-height: 100vh;
  object-fit: cover;
}
.logo-div .logo {
  width: 140px;
  height: 56px;
  margin-top: 24px;
  margin-left: 24px;
}

.logo-div {
  position: absolute;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3));
  width: 50vw;
  height: 100%;
  min-height: 100vh;
}

.login-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgb(255, 255, 255);
  width: 100%;
  max-width: 50%;
  min-width: 50vw;
  margin: 0px;
}

.form-div {
  width: 100%;
  height: 666ox;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid green;
}

.forgot-pwd {
  margin-top: 0.5rem;
  color: #274b28;
  width: 100%;
  font-size: 16px;
  text-decoration: underline;
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
}

@media only screen and (max-width: 735px) {
  .login-image-div {
    display: none;
  }

  .form-div h1 {
    text-align: center !important;
  }
}
@media only screen and (max-width: 383px) {
  .forgot-pwd {
    font-size: 12px;
  }

  .login-input {
    height: 42px !important;
    border-radius: 15px !important;
  }

  .green-button,
  .green-button:hover {
    padding: 24px;

    width: -webkit-max-content;

    width: -moz-max-content;

    width: max-content;
    font-size: 16px !important;
  }

  .wspace_4 {
    text-align: left;
  }
}

.map-styles {
  width: 380px;
  height: 496px;
}

@media screen and (max-width: 415px) {
  .map-styles {
    width: 330px;
    height: 496px;
  }
}

@media screen and (max-width: 340px) {
  .map-styles {
    width: 300px;
    height: 496px;
  }
}

@media screen and (max-width: 336px) {
  .map-styles {
    width: 250px;
    height: 496px;
  }
}

.small-slider-container {
  height: 100%;
  width: 100%;
  position: relative;
  margin: auto;
  overflow: hidden;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.12);
}

.small-slider-container .active {
  display: inline-block;
}

.small-slider-container .inactive {
  display: none;
}

.small-slider-container .slides {
  height: 164px;
  width: 100%;
  /* position: relative; */
}

.small-slider-container .slide-image {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.small-slider-container .previousTabArrow,
.small-slider-container .nextTabArrow {
  cursor: pointer;
  z-index: 9;
  position: absolute;
  top: 50%;
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -1rem;
  font-size: 16px;
  border-radius: 0px 5px 5px 0px;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border-radius: 50%;
}

.small-slider-container .previousTabArrow {
  left: 16px;
}
.small-slider-container .previousTabArrow:hover,
.small-slider-container .nextTabArrow:hover {
  transition: all 0.5s ease-in;
}

.small-slider-container .nextTabArrow {
  right: 16px;
}

.small-slider-container .all-dots {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  top: 85%;
  justify-content: center;
  z-index: 10;
}

.small-slider-container .dot {
  cursor: pointer;
  height: 8px;
  width: 8px;
  margin: 0px 3px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: inline-block;
}

.small-slider-container .active-dot,
.small-slider-container .dot:hover {
  background-color: rgba(255, 255, 255, 0.5);
}



.new-patch-slider-container {
  max-height:100%;
  height: 164px;
  width: 100%;
  position: relative;
  margin: auto;
  overflow: hidden;
  box-shadow:none !important;
}

.new-patch-slider-container .active {
  display: inline-block;
}

.new-patch-slider-container .inactive {
  display: none;
}

.new-patch-slider-container .slides {
  height: 100%;
  width: 100%;
  position: relative;
}

.new-patch-slider-container .slide-image {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}


.new-patch-slider-container .prePatch,
.new-patch-slider-container .nextPatch {
  cursor: pointer;
  z-index: 9;
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
  height: 32px;
  width: 32px;
  display: flex;
  font-size: 11px;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  border-radius: 50%;
}

.new-patch-slider-container .prePatch {
  left: 8px;
}
.new-patch-slider-container .prePatch:hover,
.new-patch-slider-container .nextPatch:hover {
  transition: all 0.5s ease-in;
}

.new-patch-slider-container .nextPatch {
  right: 8px;
}

.new-patch-slider-container .all-dots {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  top: 85%;
  justify-content: center;
  z-index: 9;
}

.new-patch-slider-container .dot {
  cursor: pointer;
  height: 8px;
  width: 8px;
  margin: 0px 4px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: inline-block;
}

.new-patch-slider-container .active-dot,
.new-patch-slider-container .dot:hover {
  background-color: rgba(255, 255, 255, 1);
}

.new-patch-div {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.new-patch-div .left-div {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.new-patch-div .right-div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

@media only screen and (max-width: 732px) {
  .new-patch-div .right-div {
    flex-direction: column;
  }

  .small-p,
  .textGray {
    text-align: left;
  }
}

@media only screen and (max-width: 500px) {
  .new-patch-div {
    width: 100%;
    flex-direction: column;
  }
  .left-div{
    margin-left:24px;
  }
  .right-div{
    align-items: flex-start !important;
    margin-left: 24px;
    margin-top: 10px;
  }
}

.large-slider-container {
  height: 488px;
  width: 100%;
  position: relative;
  margin: auto;
  overflow: hidden;
}

.active {
  display: inline-block;
}

.inactive {
  display: none;
}

.large-slider-container .slides {
  height: 488px;
  width: 100%;
  position: relative;
}

.large-slider-container .sliding-image {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px !important;
}

.large-slider-container .slide-title {
  font-size: 28px;
  line-height: 35px;
  color: #ffffff;
  font-weight: 900;
}

.large-slider-container .slide-text {
  font-size: 18px;
  line-height: 23px;
  color: #f1fff2;
  margin-top: 8px;
}

.large-slider-container .prevPatch,
.large-slider-container .nextPatch {
  cursor: pointer;
  z-index: 9;
  position: absolute;
  top: 50%;
  height: 48px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -2rem;
  font-size: 20px;
  border-radius: 50%;
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
}

.large-slider-container .prevPatch {
  left: 16px;
}

.large-slider-container .prevPatch:hover,
.large-slider-container .nextPatch:hover {
  transition: all 0.5s ease-in;
}

.large-slider-container .nextPatch {
  right: 16px;
}

.large-slider-container .all-dots {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  top: 90%;
  justify-content: center;
  z-index: 10;
}

.large-slider-container .dot {
  cursor: pointer;
  height: 12px;
  width: 12px;
  margin: 0px 3px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: inline-block;
}

.large-slider-container .active-dot,
.large-slider-container .dot:hover {
  background-color: rgba(255, 255, 255, 0.5);
}

.slider-left-sec {
  margin: 32px 0px 0px 32px;
  display: flex;
  flex-direction: column;
}

.slider-right-text {
  margin-top: 32px;
  display: flex;
}
.slider-right-text .btn {
  background: #f1fff2;
  border: 1px solid #274b28;
  border-radius: 100px;
  /* padding: 10px 32px 8px; */
  cursor: pointer;
  text-align: center;
}

.slider_wrapper{
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 721px) {
  .slider-left-sec {
    width: 90%;
    display: flex;
    align-items: center;
    margin:24px;
  }
  .slider_wrapper{
    flex-direction: column;
    height: 100%;
  }
  .slider-right-text{
    width: 95%;
    display: flex;
    justify-content: flex-end;
    margin-top: 0px;
    margin-bottom: 32px;

  }
  .large-slider-container{
    max-width: 90%;
  }
}

/* @media only screen and (max-width: 453px) {
  .large-slider-container .slide-title {
    font-size: 16px;
  }
  .large-slider-container .slide-text {
    font-size: 15px;
  }
  .slider-right-text {
    margin-right: 0px !important;
  }

  .slider-right-text {
    padding: 10px 32px 8px;
  }
  .slider-right-text .btn {
    border-radius: 100px;
  }
  .slide-text {
    font-size: 13px;
  }
}

@media only screen and (max-width: 413px) {
  /* .slider-left-sec {
    margin: 0px;
  }
  .slider-left-sec .slide-title {
    line-height: 1;
    font-weight: 100;
  } */
  /* .slider-right-text {
    margin-top: 0px;
    padding: 4px 16px 0px;
  }
  .large-slider-container .slide-text {
    font-size: 13px;
  }
} */ 

.tree-details-slider-container {
  height: 100%;
  width: 100%;
  /* max-width: 360px; */
  min-height: 400px;
  margin-top: 8px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  background: rgb(91, 205, 99);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.12);
}

.active {
  display: inline-block;
}

.inactive {
  display: none;
}

.tree-details-slider-container .slides {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  position: relative;
}

.tree-details-slider-container.slide-image {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.tree-details-slider-container .slide-title {
  font-size: 24px;
  line-height: 30px;
}
.tree-details-slider-container .slide-text {
  font-size: 16px;
  line-height: 20px;
  margin: 6px 0 0 !important;
}
.tree-details-slider-container .slide-title,
.tree-details-slider-container .slide-text {
  color: white;
}
.tree-details-slider-container .slide-text {
  margin-top: 8px;
}

.tree-details-slider-container .previousArrow,
.tree-details-slider-container .nextArrow {
  cursor: pointer;
  z-index: 9;
  position: absolute;
  top: 5%;
  font-size: 20px;
  color: white;
}

.tree-details-slider-container .previousArrow {
  right: 56px;
}

.tree-details-slider-container .previousArrow:hover,
.tree-details-slider-container .nextArrow:hover {
  transition: all 0.5s ease-in;
}

.tree-details-slider-container .nextArrow {
  right: 24px;
}

.tree-details-slider-container .all-dots {
  width: 100%;
  position: absolute;
  display: flex;
  bottom: 16px;
  justify-content: center;
  z-index: 200;
}
.tree-details-slider-container .dot {
  cursor: pointer;
  height: 8px;
  width: 8px;
  margin: 0px 3px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: inline-block;
}

.tree-details-slider-container .active-dot,
.tree-details-slider-container .dot:hover {
  background-color: rgba(255, 255, 255, 0.5);
}
.patch-detail-slider-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  height: 348px;
  padding: 16px 24px;
}

.slider-inner-sec {
  display: flex;
  flex-direction: row;
}
.slider-inner-sec-div1 {
  background: rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 12px 16px;
  width: 148px;
}

.slider-inner-sec-div2 {
  background: rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 12px 16px;
  margin-left: 16px;
  width: 148px;
}

@media screen and (max-width: 400px) {
  /* .patch-detail-slider-inner {
    padding: 0px;
  } */
  .tree-details-slider-container{
    min-height: 500px;
  }
  .slider-inner-sec {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 200px;
    margin-bottom: 100px;
    width: 100%;
    justify-content: space-evenly;
  }
  .slider-inner-sec-div2 {
    margin-left: 0px;
    margin-top: 10px;
  }
}

.patch-detail-outer-div {
  width: 100%;
  margin: "96px auto 0";
}

.map {
  width: 100%;
  /* height:400px; */
}

.patch-bottom-row2 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin: 48px 0px;

}

.patch-bottom-row2-div1 {
  display: flex;
  flex-direction: column;
  width: 45%;
  margin-left: 32px;
}

.patch-map {
  width: 390px;
  height: 500px;
}

.patch-bottom-row2-div2 {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-left: 32px;
  width: 45%;
}
.map_facts{
  display: flex;
  width: 100%;
  margin: 40px 0px;
}
.facts{
  width: 48%;
  margin-right: 32px;
  display: flex;
  flex-direction: column;
}
.maps{
  width: 48%;
}

@media screen and (max-width: 768px) {
  .patch-bottom-row2-div1{
    width: 100%;
    margin: 0px;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    margin-left:0px; */
  }
  /* .map{
    width:80%;
    height:100px;
  } */
  .map_facts{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .maps{
    width: 90%;
  }
  .facts{
    width: 90%;
    margin-right:0px;
    margin-bottom: 24px;
  }

  .facts .textGray{
    text-align: center;
  }
 

  .patch-bottom-row2-div2 {
    width: 100%;
    margin:0px;
    margin-top:16px;
  }
  .patch-bottom-row2{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0px;
  }
}
.editpayment_cancel_btn {
  background: rgb(245, 245, 245);
  border-radius: 100px;
  padding: 12px;
  cursor: pointer;
  margin-right: 16px;
  width: 140px;
  text-align: center;
}

.editpayment_update {
  width: 220px;
  background: black;
  color: white;
  border-radius: 10px;
  padding: 10px 24px 8px;
}

.main-padding {
  padding: 24px;
}

@media screen and (max-width: 380px) {
  .main-padding {
    padding: 5px;
  }
}


.payment-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}
.wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.payment-card {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px 8px 0 0;
  max-width: 100%;
  min-height: 136px;
  max-height: 400px;
  padding: 0 24px;
  margin-top: 32px;
  position: relative;
}

.payment-card-left-div {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  flex-direction: column;
}
.payment-card-right-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.payment-card-right-div .right-div {
  display: flex;
}

.payment-detail-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
}

.payment-details {
  background: #f1fff2;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px 19px;
  width: 100%;
  max-width: 408px;
  min-width: 376px;
  display: flex;
  align-items: center;
  height: 100%;
  /* max-height: 80px; */
}

/* @media only screen and (max-width: 770px) {
  h3 {
    font-size: 14px;
  }
  p {
    font-size: 12px;
  }
} */

@media only screen and (max-width: 1009px) {
  .payment-card {
    /* height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #ffffff;
        box-shadow: 0px 1px 8px rgb(0 0 0 / 12%);
        border-radius: 8px 8px 0 0;
        max-width: 100%;
        min-height: 136px;
        max-height: 144px;
        padding: 0 24px;
        margin-top: 32px; */
    flex-direction: column;
    padding: 18px;
  }

  .payment-details {
    max-height: 68px;
    min-width: 0px;
  }

  .payment-card-right-div .right-div {
    display: flex;
    position: absolute;
    top: 10px;
  }
}
.payment-margin {
  margin-left: 16px;
}
@media only screen and (max-width: 768px) {
  .payment-wrapper{
    width: 95%;
  }
  .payment-card-left-div{
    width: 100%;
  }
}
@media only screen and (max-width: 420px) {
  .small-p {
    font-size: 12px;
  }
  .payment-details{
    flex-direction: column;
    max-height: -webkit-max-content;
    max-height: -moz-max-content;
    max-height: max-content;
  }
}
@media only screen and (max-width: 290px) {
  .payment-card {
    padding: 5px;
  }
  .payment-details {
    padding: 5px;
  }
  .payment-margin {
    margin-left: 0px;
  }
}

.settings-wrapper {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 12%) 0px 1px 8px;
  margin-top: 24px;
  width: 100%;
}

.settings-div {
  display: flex;
  height: 100%;
}

.settings-tab {
  display: flex;
  align-items: start;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  max-width: 150px;
  min-width: 0px;
}

.settings-right-wrapper {
  border-left: 1px solid rgb(238, 238, 238);
  padding: 32px;
  width: 100%;
  height: 100%;
}

.settings-right-inner-wrap {
  height: 100%;
  min-height: 520px;
  position: relative;
}
.settings-right-profile-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  max-width: 200px;
  margin: 32px auto;
}

.profile-pic {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 124px;
  width: 124px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(39, 75, 40);
  position: relative;
  box-shadow: rgb(0 0 0 / 25%) 0px 8px 24px;
}
.form-row {
  display: flex;
  width: 100%;
  margin-right: 16px;
  grid-row-gap: 0px;
  row-gap: 0px;
  margin-top: 12px;
}

.settings-form-input {
  padding: 8px 16px !important;
  color: #424242 !important;
  font-size: 18px !important;
  line-height: 23% !important;
  height: 100%;
}

.submitBtn {
  position: "absolute";
  bottom: 0;
  right: 24;
}

.form-row-right-field {
  margin-left: 16px;
  grid-row-gap: 0;
  row-gap: 0;
}

.settings-tab-header {
  margin-left: 5px;
}
@media only screen and (max-width: 756px) {
  .settings-wrapper {
    border-radius: 25px;
    width: 100%;
    /* margin : 0px 16px; */
  }
  .settings-div {
    flex-direction: column;
  }
  .settings-tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    max-width: 100%;
    width: 100%;

    /* width: 100%;
    padding: 24px;
    max-width: 150px;
    min-width: 0px; */
  }
  .form-row {
    flex-direction: column;
  }
  .submitBtn {
    display: flex;
    justify-content: center;
  }
  .settings-right-inner-wrap h3 {
    text-align: center;
  }
  .form-row-right-field {
    margin-left: 0;
  }

  .settings-form-input {
    padding: 8px 16px !important;
    color: #424242 !important;
    font-size: 18px !important;
    line-height: 23% !important;
    height: 100%;
    border-radius: 10px;
  }
  h2,
  p {
    text-align: center;
  }
}

@media only screen and (max-width: 995px) {
  h2,
  p {
    text-align: center;
  }
}

@media only screen and (max-width: 300px) {
  .settings-tab {
    padding: 0px;
  }
  .settings-tab-header {
    margin-left: 0px;
  }
}

.card-expiry-div {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* flex-wrap: wrap; */
}

.card-expiry-div .textfield {
  height: 64px;
  color: #424242;
  background: #fafafa;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  font-size: 18px;
  line-height: 23px;
  box-shadow: none;
  margin: 10px 0 0;
  padding: 0px 0px 0px 16px;
  width: 154px;
}

.item {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0;
  margin: 0 24px;
  border: none;
}

.bottom-div-wrapper {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.cancel-field {
  background: #f5f5f5;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  height:46px;
  font-size: 20px  !important;
  cursor: pointer;
  padding: 10px 32px 8px;
  margin-right: 16px;
}

.submit-field {
  color:"#fff" !important;
  position: relative;
  z-index: 1;
  border: 0px solid #274b28;
  background-color: #274b28;
}
@media screen and (max-width: 558px) {
  .card-expiry-div {
    display: flex;
    flex-direction: column;
  }
  .textfield {
    width: 100% !important;
  }
  .item {
    margin: 0;
  }
  /* .cancel-field {
    padding: 10px 12px 8px;
  } */
}

.cart-wrapper {
  padding: 0px 0px 120px;
  margin-top: 8px;
}

.cart-wrapper2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.cart-progress-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background: #274b28;
  height: 80px;
}

.cart-main-conaitner {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
  width: 100%;
  padding: 48px 48px 96px;
}

.cart-main-conaitner .container-inner {
  display: flex;
  justify-content: flex-start;

  width: 100%;
  height: 100%;
  flex-direction: column;
}
.container-inner .top-row {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}

.container-inner .cart-pymnt {
  /* height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgb(0 0 0 / 12%);
  border-radius: 8px 8px 0 0;
  max-width: 100%;
  min-height: 136px;
  max-height: 400px;
  padding: 0 24px;
  margin-top: 32px;
  position: relative; */
  min-height: 136px;
  /* display: flex; */
  width: 100%;
  /* border: 1px solid green; */
  position: relative;
  margin-top: 32px;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgb(0 0 0 / 12%);
  border-radius: 8px 8px 0 0;
  padding: 5px 5px 10px 10px;
}
.col1col2-div {
  display: flex;
  flex-direction: row;
  width: 75%;
  justify-content: space-around;
  /* border: 1px solid yellowgreen; */
  min-height: 136px;
  align-items: center;
  padding: 5px;
}
.cart-pymnt .col1 {
  /* display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start; */
  /* width: 100%; */
}

.cart-pymnt .col2 {
  width: 60%;
}

.cart-pymnt .col3 {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  min-height: 136px;
  align-items: center;
  margin-right: 5px;
  margin-top: 5px;
}

.cart-main-conaitner .right-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  max-width: 320px;
  margin-left: 32px;
}

.right-div .inner-div {
  display: flex;
  flex-direction: column;
  border: 1px solid rgb(39, 75, 40);
  background: rgb(241, 255, 242);
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 25%) 0px 1px 8px;
  padding: 24px;
}

.col1col2-div .pymnt-details {
  background: #f1fff2;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px 19px;
  width: 100%;
  max-width: 408px;
  min-width: 0px;
  display: flex;
  align-items: center;
  height: 100%;
  /* max-height: 80px; */
}

.pymnt-details .item1 {
  display: flex;
  align-items: center;
  width: 100%;
}
.pymnt-details .item2 {
  display: flex;
  align-items: center;
  width: 100%;
}

.pymnt-details .eidt-icon {
  width: 24px;
  height: 24px;
}
.pymnt-details .delete-icon {
  width: 24px;
  height: 24px;
}

.item1 .second-div {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}

.item2 .second-div {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
}

.cart-footer {
  position: fixed;
  bottom: 0px;
  padding: 32px;
  height: 80px;
  background: rgb(255, 255, 255);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.cart-footer .p1 {
  margin: 12px 0px;
  font-size: 20px;
  line-height: 130%;
}

.cart-footer .p2 {
  color: rgb(66, 66, 66);
  font-size: 20px;
  margin: 0px 32px 0px 16px;
  line-height: 120%;
}
.text-align-left {
  text-align: left;
}

@media only screen and (max-width: 1000px) {
  body {
    overflow-y: scroll;
  }
  .top-row {
    margin-top: 24px;
    margin-left: 16px;
  }
  .cart-main-conaitner {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .cart-main-conaitner .right-div {
    margin-left: 0px;
    margin-top: 10px;
  }
}

@media only screen and (max-width: 913px) {
  body {
    overflow-y: scroll;
  }
  .right-div .inner-div {
    margin-top: 10px;
  }
  .cart-wrapper {
    margin-top: 8px;
  }
}
@media only screen and (max-width: 639px) {
  .footer-div1 .p1 {
    font-size: 13px;
  }
  .footer-div1 .p2 {
    font-size: 13px;
    /* margin: 0; */
  }
}

@media only screen and (max-width: 430px) {
  .footer-div1 .p1 {
    font-size: 13px;
  }
  .footer-div1 .p2 {
    font-size: 13px;
    margin-left: 0;
  }
  .cart-pymnt {
    padding: 16px !important;
  }
  .col1col2-div {
    padding: 0px;
  }
}

@media only screen and (max-width: 717px) {
  .col1col2-div {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
  }

  .cart-pymnt .col3 {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    min-height: 136px;

    margin-right: 5px;
  }
}

@media only screen and (max-width: 603px) {
  .col1col2-div {
    flex-direction: column;
  }

  .cart-pymnt .col3 {
    display: flex;
    position: absolute;
    top: 12px;
    right: 0;
    min-height: 5px;

    margin-right: 5px;
  }
}
@media only screen and (max-width: 855px) {
  .col1col2-div .pymnt-details {
    padding: 15px 6px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    justify-content: center;
  }
  .cart-pymnt .col2 {
    margin-top: 12px;
    width: 90vw;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 431px) {
  .col1col2-div .pymnt-details {
    padding: 15px 6px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
    justify-content: center;
  }
  .cart-pymnt .col2 {
    margin-top: 12px;
    width: 90vw;
    display: flex;
    justify-content: center;
  }
}

@media only screen and (max-width: 450px) {
  .pymnt-details {
    /* background-color: red !important; */
    flex-direction: column;
    max-height: -webkit-max-content;
    max-height: -moz-max-content;
    max-height: max-content;
    height: -webkit-max-content;
    height: -moz-max-content;
    height: max-content;
    grid-gap: 20px;
    gap: 20px;
    padding: 15px !important;
    width: 90% !important;
  }
  .col1col2-div .pymnt-details {
    padding: 15px 0px;
  }
  .payment-light-circle-icon,
  .eidt-icon {
    width: 30px;
    height: 30px;
  }
  .trash-icon,
  .trash-icon {
    width: 30px;
    height: 30px;
  }
  .col1 {
    line-height: 0;
  }

  .col1 h3 {
    font-size: 12px;
  }
  .col1 p {
    font-size: 10px !important;
  }

  .item2 .second-div {
    margin-left: 0px;
  }

  .item1 .second-div {
    margin-left: 0px;
  }
}

@media only screen and (max-width: 430px) {
  .cart-main-conaitner {
    padding: 10px 10px;
  }
}

@media only screen and (max-width: 290px) {
  /* .cart-footer {
    flex-direction: column;
  } */

  .step2_content_inner-div {
    padding: 4px !important;
  }
}

/* Cart Step 2 */

.step2_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 48px 48px 96px;
}

.step2_content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  max-width: 992px;
  /* min-width: 900px; */
  border-radius: 8px;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12);
}

.content_header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: #f1fff2;
  border-radius: 8px 8px 0px 0px;
  padding: 24px;
}

.step2_content_inner-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #ffffff;
  border-radius: 0px 0px 8px 8px;
  padding: 24px;
}

.mt {
  margin-top: 15px;
}

@media only screen and (max-width: 500px) {
  .step2_wrapper {
    padding: 5px;
  }
}

/* step 3 */

.step3_wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 48px 48px 96px;
}

.step3_left_div {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  height: 100%;
  padding: 0px 96px;
}

.step3_left_div_inner-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.step3_left_div_inner-wrap2 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  box-shadow: rgb(0 0 0 / 12%) 0px 1px 8px;
  border-radius: 8px;
}
.step3_left_div_header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  background: rgb(241, 255, 242);
  border-radius: 8px 8px 0px 0px;
  padding: 24px;
}
.step3_left_div_content {
  background: rgb(255, 255, 255);
  border-radius: 0px 0px 8px 8px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 24px 24px 48px;
}
.step3_pay_card {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 24px;

  padding: 26px;
  border-radius: 8px;
}
.step3_pay_card_div1 {
  display: flex;
  align-items: center;
  margin: 0px 24px;
}
.step3_pay_card_div2 {
  display: flex;
  align-items: center;
}
.step3_pay_card_btn {
  margin-top: 24px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: rgba(0, 0, 0, 0.04);
  border-radius: 100px;
  padding: 16px 32px;
  width: 100%;
  max-width: 300px;
  cursor: pointer;
}

.step3_right_div {
  display: flex;
  flex-direction: column;

  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 12%) 0px 1px 8px;
  padding: 32px;
}

.step3_right_inner_div {
  display: flex;
  flex-direction: column;
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 12%) 0px 1px 8px;
  padding: 32px;
}

@media only screen and (max-width: 1300px) {
  .step3_wrapper {
    flex-direction: column;
    padding: 0px;
  }
}

@media only screen and (max-width: 550px) {
  .step3_pay_card_div1 {
    margin: 0;
  }
  .step3_left_div {
    padding: 10px 10px 10px;
  }
  .step3_pay_card {
    padding: 10px;
  }
  .step3_pay_card_btn {
    max-width: 100%;
    justify-content: center;
  }
}

@media only screen and (max-width: 375px) {
  .step3_left_div_content {
    padding: 5px;
  }
}

@media only screen and (max-width: 375px) {
  .step3_pay_card {
    padding: 0px;
  }
}

@media only screen and (max-width: 389px) {
  .step3_right_inner_div {
    padding: 10px;
  }
}

@media only screen and (max-width: 349px) {
  .step3_right_div {
    padding: 10px;
  }
}

@media only screen and (max-width: 300px) {
  .step3_right_div {
    padding: 0px;
  }
}

@media only screen and (max-width: 768px) {
  .cart-progress-bar {
    height: 40px;
    padding: 32px 0px;
  }
  .step3_right_div {
    align-items: center;
    width: 100%;
  }
}

