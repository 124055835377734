.content-div {
  margin: 24px 16px 0;
}

.anticon-caret-down svg {
  height: 16px;
  width: 16px;
}

.small-p {
  font-size: 14px;
  line-height: 18px;
  margin: 0;
}

.green-button,
.green-button:hover {
  background: #274b28 !important;
  border-radius: 100px;
  color: #ffffff !important;
  text-align: center;
  padding: 28px 0;
  max-width: 274px;
  width: 100%;
  font-size: 20px !important;
  line-height: 25px !important;
}

.green-button>div {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

/* Preloader */

.loader,
.component-loading {
  display: flex !important;
  height: 100% !important;
  width: 100% !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}

.pulse-loader {
  display: contents !important;
}

.pulse-loader>div {
  background: none;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.isMobile{
  display: none;
}

.loader ._loading_overlay_overlay {
  background-color: #ffffff !important;
}

.component-loading ._loading_overlay_overlay {
  background-color: transparent !important;
  width: 100% !important;
}

.loading-text {
  font-size: 18px;
  color: #151515;
  margin: 0 !important;
  line-height: 114%;
}

/* Main content */

/* .content {
  margin: 0;
  min-height: 100vh;
  margin-left: 272px;
  background: #f3f5f3;
  overflow-x: auto;
} */

.content-for-card {
  margin: 0;
  min-height: 100vh;
  max-width: 100%;
  margin-left: 0;
  background: #f3f5f3;
}

/* .content-body {
  padding: 10px;
} */

/* .content-body {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-top: 96px;
  padding: 24px 32px 96px 32px;
} */

/* Breadcrumb */

.breadcrumb {
  padding-left: 32px;
  margin: 0;
  /* position: fixed; */
  top: 0;
  width: 100%;
  background-color: #ffffff;
  box-shadow: 0 5px 5px rgb(0 0 0 / 30%);
  height: 72px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  /* z-index: 100; */
}

@media only screen and (max-width: 1000px) {
  .breadcrumb {
    padding-left: 100px;
    margin: 0;
    /* position: fixed; */
    top: 0;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 5px 5px rgb(0 0 0 / 30%);
    height: 72px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
}

.breadcrumb-for-cart {
  padding-left: 48px;
  margin: 0;
  /* position: fixed; */
  top: 0;
  width: calc(100%);
  background-color: #ffffff;
  box-shadow: 0 5px 5px rgb(0 0 0 / 30%);
  height: 72px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

/* Sidebar */

.ant-menu-item-active {
  background-color: #274b28 !important;
}

.menu-item {
  margin-left: 0 !important;
  padding-left: "3em" !important;
  height: "4.5em" !important;
  display: "flex" !important;
  align-items: "center" !important;
}

.ant-card-body {
  width: 100% !important;
  display: flex !important;
  padding-bottom: 0px !important;
}

.ant-btn {
  border: none !important;
  box-shadow: none !important;
}

.ant-btn>span {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ant-btn:hover,
.ant-btn:active,
.ant-btn:focus {
  color: #ffffff;
  background-color: #274b28;
  cursor: pointer !important;
  border: none !important;
}

.ant-btn[disabled]:hover {
  cursor: context-menu !important;
}

/* 404 & Support Message */

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  height: 100%;
  min-height: 100vh;
  flex-direction: column;
}

.container_404 {
  background-color: rgba(39, 75, 40, 0.95);
  color: #ffffff;
  height: 356px;
  width: 100%;
  max-width: 576px;
  min-width: 425px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  flex-direction: column;
  padding: 0px 40px;
}

/* Sign up & Sign in Forms */

.signing-form {
  height: 100vh;
  width: 100vw;
  background-color: #274b28;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.btn-sm-yellow,
.btn-sm-yellow:hover,
.btn-sm-yellow:active,
.btn-sm-yellow:focus {
  border-radius: 5px;
  background-color: #fed64b !important;
  color: #151515 !important;
  padding: 6px 16px 3px;
  display: flex;
  height: 2.5rem !important;
  justify-content: center;
  align-items: center;
  width: 120px;
}

.btn-sm-black,
.btn-sm-black:active,
.btn-sm-black:focus,
.btn-sm-black:hover {
  border-radius: 5px;
  background-color: #274b28 !important;
  color: #ffffff !important;
  padding: 6px 16px 3px;
  display: flex;
  height: 2.5rem !important;
  justify-content: center;
  align-items: center;
  width: 120px;
}

.btn-lg-black,
.btn-lg-black:active,
.btn-lg-black:focus,
.btn-lg-black:hover {
  margin-top: 2rem;
  border-radius: 5px;
  color: #ffffff;
  background-color: #274b28;
  border-color: rgb(21, 21, 21);
  width: 100%;
  min-height: 3rem !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.btn-back {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  background-color: #274b28;
  color: #ffffff;
  margin-top: 2rem;
  font-size: 15px;
  line-height: 18.97px;
  letter-spacing: 2px;
  border-radius: 10px;
  width: 160px;
  height: 50px;
  cursor: pointer !important;
  border: 2px solid #274b28;
}

.forgot_link,
.forgot_link:hover {
  margin-top: 0.5rem;
  color: #274b28;
  width: 100%;
  font-size: 16px;
  text-decoration: underline;
  display: flex;
  cursor: pointer;
  justify-content: flex-start;
}

.title {
  color: #274b28;
  font-size: 32px;
  line-height: 40px;
}

.black-title {
  color: #010101;
  font-size: 28px;
  line-height: 35.42px;
  text-transform: uppercase;
  margin-top: 1rem;
}

.error-description,
.support-description {
  padding: 0;
  margin: 0;
  color: #010101;
  font-size: 28px;
  line-height: 35.42px;
}

.description {
  color: #010101;
  font-size: 18px;
  line-height: 25.42px;
}

.logout {
  /* position: absolute; */
  bottom: 24px;
  width: 100%;
  color: rgba(255, 255, 255, 0.65) !important;
  text-decoration: none !important;
  cursor: pointer !important;
  margin: 0.3em 0;
  height: 3.5em;
  display: flex;
  align-items: center;
}

.logout:hover {
  width: 80% !important;
  background-color: rgba(0, 0, 0, 0.24) !important;
  color: rgba(255, 255, 255, 1) !important;
  border-radius: 0px 50px 50px 0px !important;
  text-decoration: none !important;
  cursor: pointer !important;
}

/* Dashboard content */

.dropdown-items {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 12px !important;
  margin: 4px 0 !important;
}

.dropdown-items-active,
.dropdown-items:hover {
  background-color: #f1fff2 !important;
  cursor: pointer !important;
}

/* Card View */

/* .patch-card {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  max-width: 100%;
  min-height: 164px;
  max-height: 172px;
  padding: 0 24px 0 0;
  margin-top: 32px;
} */

.patch-details {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  max-width: 100%;
  min-height: 488px;
  max-height: 500px;
  padding: 0;
}

/* .payment-card {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px 8px 0 0;
  max-width: 100%;
  min-height: 136px;
  max-height: 144px;
  padding: 0 24px;
  margin-top: 32px;
} */

/* .payment-details {
  background: #f1fff2;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px 19px;
  width: 100%;
  max-width: 408px;
  min-width: 376px;
  display: flex;
  align-items: center;
  height: 100%;
  max-height: 80px;
} */

.payment-circle-icon {
  background: rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  padding: 12px;
  height: 48px;
  width: 48px;
}

.payment-light-circle-icon {
  border: 1px solid #274b28;
  border-radius: 50%;
  height: 48px;
  width: 48px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.overdue {
  background: #f44336;
  color: #ffffff;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 24px;
  /* min-width: 0px; */
}

.in-active {
  background: #e9e647;
  color: #ffffff;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 24px;
}

.within-due {
  background: #fff3e0;
  color: #424242;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
}

/* Search bar styles */

span[aria-label="search"] svg {
  height: 24px;
  width: 24px;
  color: #757575 !important;
}

::placeholder {
  color: #274b28 !important;
  font-size: 20px !important;
  line-height: 25px !important;
  opacity: 0.8;
  word-spacing: -6px;
}

/* @media screen and (max-width: 1199px) {
  .search-field {
    max-width: 100%;
  }
} */
@media screen and (max-width: 556px) {
  .search-field {
    margin-left: 6px !important;
    font-size: 15px !important;
    width: 182px !important;
  }

  /* .search-field span {
    padding: 0px !important;
  } */
  .search-field input {
    margin-left: 3px !important;
  }

  .content-div {
    margin: 32px 16px;
  }
}

.search-field {
  display: flex;
  position: relative;
  width: 100%;
  height: 64px;
  /* margin-bottom: 23px; */
  flex-grow: 2;
  min-width: 130px;
}

.search-field input {
  background: none !important;
  word-spacing: 0px !important;
  margin-left: 12px;
  font-size: 20px;
  line-height: 25%;
  color: #274b28 !important;
}

/* Share button */

.share-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 16px 0;
  color: blue;
}

.ant-modal-content {
  border-radius: 8px !important;
}

.trash-icon {
  background: #ffebee;
  border: 1px solid #f44336;
  border-radius: 50%;
  height: 48px;
  width: 48px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Modal */

.modalTitle {
  height: 72px;
  padding-left: 24px;
  font-weight: 900;
}

.areaForm {
  padding: 32px 24px;
}

.areaForm .areaInputContainer {
  height: 64px;
  position: relative;
  margin-top: 16px;
}

.language-selector .ant-select-selector {
  height: 44px !important;
  border-radius: 5px;
  background: none !important;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100% !important;
}

.language-selector .ant-select-selection-item {
  font-size: 18px !important;
  line-height: 130% !important;
}

.pointer {
  cursor: pointer;
}

@media only screen and (max-width: 868px) {
  .patch-card-resp {
    flex-direction: column;
    margin-top: 0 !important;
    height: 45px;
    width: 97px !important;
  }
}

@media only screen and (max-width: 868px) {
  .patch-card-resp .text-props {
    width: 119px !important;
    height: 24px !important;
    margin-top: 8px;
  }
}

@media only screen and (max-width: 868px) {
  .patch-card-resp .detail-btn {
    height: 26px !important;
    margin-top: 21px;
  }
}

@media only screen and (max-width: 868px) {
  .patch-card-head {
    margin-left: -1px !important;
    margin-top: -16px !important;
  }
}

.patch-card-wrapper {
  width: 100%;
}

.patch-card {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 5px;

  margin-top: 35px;
  padding: 10px;
}

.patch-card .slider-div {
  width: 280px;
  height: 100%;
}

.patch-card .patch-card-right {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-around;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12);
  padding-left: 32px;
  min-width: 0px;
}

.patch-btn1 {
  background: #eefbef;
  border: 1px solid #e0e0e0;
  display: flex;
  flex-direction: row;
  width: 179px;
  height: 45px;
  border-radius: 100px;
  
  justify-content: center;
  align-items: center;
  min-width: 0px;
  margin-right: 5px;
}

.patch-btn2 {
  width: 179px;
  height: 45px;
  background: #fff3e0;
  border: 1px solid #e0e0e0;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 0;
}

.patch-bottom-div {
  display: flex;
  flex-direction: row;
  width: 100%;

  justify-content: space-between;
  align-items: center;

  min-width: 0px;
}

.patch-bottom-left-div {
  display: flex;
  flex-direction: row;
  width: 300px;
  min-width: 0px;
}

.bottom-right-div {
  display: flex;
  flex-direction: row;
  width: 226px;
  height: 44px;
  justify-content: space-around;
  min-width: 0px;
  margin-right: 10px;
}

.details-btn {
  width: 130px;
  height: 44px;
  font-family: "Circular Std";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 25px;
  display: flex;
  align-items: center;
  text-align: center;
  background: #f1fff2;

  border: 1px solid #274b28;
  border-radius: 100px;
  justify-content: center;
  flex-grow: 3;
  margin-right: 14px !important;
}

.h3-text {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  width: 264px;
  color: #274b28;
}

.searchbar-div {
  display: flex;
  width: 100%;
}

.search-btn {
  height: 100%;
  display: inline-flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12);
  background: #274b28;
  padding: 20px 24px;
  /* min-width: 0; */
  color: white;
  margin-left: 10px;
}
@media screen and (max-width: 768px) {
  .patch-card-right{
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}
  

@media only screen and (max-width: 1075px) {
  .patch-bottom-div {
    flex-direction: column;
  }

  .patch-card .patch-card-right {
    padding: 10px;
  }

  .bottom-right-div {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 44px;

    min-width: 100px;
  }

  .patch-bottom-left-div {
    display: flex;
    flex-direction: row;
    width: 100%;

    min-width: 200px;
  }

  .details-btn {
    width: 500px;
    height: 44px;
    font-family: "Circular Std";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 25px;
    display: flex;
    align-items: center;
    text-align: center;
    background: #f1fff2;

    border: 1px solid #274b28;
    border-radius: 100px;
    justify-content: center;
    margin-right: 7px !important;
  }
}

@media screen and (max-width:786px) {
  .isMobile{
    display:block;
  }
  
}
@media only screen and (max-width: 608px) {
  .patch-card {
    flex-direction: column;
    padding: 5px;
    margin:12px 0px;
  }
  .breadcrumb-for-cart{
    padding: 0px;
  }

  .patch-card .slider-div {
    width: 100%;
  }

  .patch-btn1 {
    height: 44px;
  }

  .patch-btn2 {
    height: 44px;
  }

  .patch-bottom-div {
    margin:8px 0px;
    justify-content: space-around;
  }
}

@media only screen and (max-width: 420px) {
  .search-field {
    padding: 0px;
    height: 42px !important;
  }

  .search-btn {
    /* padding: 0px; */
    height: 42px !important;
    min-width: 0px;
    font-size: 11px;
  }

  .searchbar-div {
    height: 45px;
  }

  .patch-card {
    margin-top: 8px;
  }
}

.layout-mobile {
  height: 100%;
}

@media only screen and (max-width: 995px) {
  .sidebar-responsive {
    position: absolute;
    z-index: 100;
    height: 100%;
  }

  .layout-mobile {
    height: 100vh;
  }

  .within-due {
    padding: 32px 24px;
  }

  .in-active {
    padding: 32px 24px;
  }

  .overdue {
    padding: 32px 24px;
  }
}

.search-field-wrap {
  border-radius: 8px;
  background-color: rgb(255, 255, 255);
  border: none;
  box-shadow: rgb(0 0 0 / 12%) 0px 1px 8px;
  padding: 11px 28px;
}

@media only screen and (max-width: 290px) {
  .search-field-wrap {
    padding: 0;
  }
}

@media only screen and (max-width: 378px) {
  .overdue {
    padding: 0px;
  }
}

.para {
  margin: 4px 8px 8px;
}

@media only screen and (max-width: 338px) {
  .overdue p {
    font-size: 13px !important;
  }

  .para {
    font-size: 13px !important;
    margin: 0px;
  }
}

@media only screen and (max-width: 464px) {
  .para {
    font-size: 13px !important;
    margin: 0px;
  }
}

@media only screen and (max-width: 384px) {
  .para {
    font-size: 10px !important;
    margin: 0px;
  }

  .small-p {
    font-size: 10px !important;
    /* max-width: 50px; */
  }
}

@media only screen and (max-width: 312px) {
  .in-active {
    padding: 0px;
  }
}

@media only screen and (max-width: 290px) {
  .payment-card {
    padding: 0;
  }
}

.cart-icon-container1 {
  background: #274b28;
  padding: 16px 32px;
  cursor: pointer;
  display: flex;
    width: max-content;

  align-items: center;
  justify-content: space-between;
}



.cart-icon-container2 {
  background: #f1fff2;
  padding: 23px;
  width: 96px;
  cursor: "pointer";
}

.cart-counter {
  background: rgb(241, 255, 242);
  border-radius: 50%;
  margin: 0px 16px;
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 380px) {
  .cart-icon-container1 {
    padding: 16px 6px;
  }

  .cart-icon-container2 {
    padding: 23px;
  }

  /* .cart-counter {
    margin: 0px 16px;
  } */

  .breadcrumb {
    padding-left: 60px;
  }
}

.new-patch-breadcrumb {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  /* background-color: yellow; */
}

.breadcrumb-text-div {
  display: flex;
}

@media only screen and (max-width: 568px) {
  .breadcrumb {
    padding-left: 63px;
  }


  /* .breadcrumb-text-div {
    display: none;
  } */
}

@media only screen and (max-width: 485px) {
  .breadcrumb-text-div {
    max-width: 60%;
    text-overflow: ellipsis;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .cart-icon-container1 {
    padding: 16px 2px;
  }

  .cart-counter {
    height: 25px;
    width: 25px;
    margin: 0px;
  }
}

@media only screen and (max-width: 485px) {

  .cart-icon-container1 {
    /* width: 30%; */
    height: 100%;
    /* position: relative;
    left: 284px; */
  }
}