.new-patch-div {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.new-patch-div .left-div {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.new-patch-div .right-div {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

@media only screen and (max-width: 732px) {
  .new-patch-div .right-div {
    flex-direction: column;
  }

  .small-p,
  .textGray {
    text-align: left;
  }
}

@media only screen and (max-width: 500px) {
  .new-patch-div {
    width: 100%;
    flex-direction: column;
  }
  .left-div{
    margin-left:24px;
  }
  .right-div{
    align-items: flex-start !important;
    margin-left: 24px;
    margin-top: 10px;
  }
}
