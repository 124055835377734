.tree-details-slider-container {
  height: 100%;
  width: 100%;
  /* max-width: 360px; */
  min-height: 400px;
  margin-top: 8px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  background: rgb(91, 205, 99);
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.12);
}

.active {
  display: inline-block;
}

.inactive {
  display: none;
}

.tree-details-slider-container .slides {
  height: 100%;
  width: 100%;
  border-radius: 8px;
  position: relative;
}

.tree-details-slider-container.slide-image {
  width: 100%;
  height: 100%;
  position: absolute;
  object-fit: cover;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.tree-details-slider-container .slide-title {
  font-size: 24px;
  line-height: 30px;
}
.tree-details-slider-container .slide-text {
  font-size: 16px;
  line-height: 20px;
  margin: 6px 0 0 !important;
}
.tree-details-slider-container .slide-title,
.tree-details-slider-container .slide-text {
  color: white;
}
.tree-details-slider-container .slide-text {
  margin-top: 8px;
}

.tree-details-slider-container .previousArrow,
.tree-details-slider-container .nextArrow {
  cursor: pointer;
  z-index: 9;
  position: absolute;
  top: 5%;
  font-size: 20px;
  color: white;
}

.tree-details-slider-container .previousArrow {
  right: 56px;
}

.tree-details-slider-container .previousArrow:hover,
.tree-details-slider-container .nextArrow:hover {
  transition: all 0.5s ease-in;
}

.tree-details-slider-container .nextArrow {
  right: 24px;
}

.tree-details-slider-container .all-dots {
  width: 100%;
  position: absolute;
  display: flex;
  bottom: 16px;
  justify-content: center;
  z-index: 200;
}
.tree-details-slider-container .dot {
  cursor: pointer;
  height: 8px;
  width: 8px;
  margin: 0px 3px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  display: inline-block;
}

.tree-details-slider-container .active-dot,
.tree-details-slider-container .dot:hover {
  background-color: rgba(255, 255, 255, 0.5);
}
.patch-detail-slider-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  height: 348px;
  padding: 16px 24px;
}

.slider-inner-sec {
  display: flex;
  flex-direction: row;
}
.slider-inner-sec-div1 {
  background: rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 12px 16px;
  width: 148px;
}

.slider-inner-sec-div2 {
  background: rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  padding: 12px 16px;
  margin-left: 16px;
  width: 148px;
}

@media screen and (max-width: 400px) {
  /* .patch-detail-slider-inner {
    padding: 0px;
  } */
  .tree-details-slider-container{
    min-height: 500px;
  }
  .slider-inner-sec {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 200px;
    margin-bottom: 100px;
    width: 100%;
    justify-content: space-evenly;
  }
  .slider-inner-sec-div2 {
    margin-left: 0px;
    margin-top: 10px;
  }
}
