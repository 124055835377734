.settings-wrapper {
  background: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: rgb(0 0 0 / 12%) 0px 1px 8px;
  margin-top: 24px;
  width: 100%;
}

.settings-div {
  display: flex;
  height: 100%;
}

.settings-tab {
  display: flex;
  align-items: start;
  flex-direction: column;
  width: 100%;
  padding: 24px;
  max-width: 150px;
  min-width: 0px;
}

.settings-right-wrapper {
  border-left: 1px solid rgb(238, 238, 238);
  padding: 32px;
  width: 100%;
  height: 100%;
}

.settings-right-inner-wrap {
  height: 100%;
  min-height: 520px;
  position: relative;
}
.settings-right-profile-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  max-width: 200px;
  margin: 32px auto;
}

.profile-pic {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 124px;
  width: 124px;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  border: 1px solid rgb(39, 75, 40);
  position: relative;
  box-shadow: rgb(0 0 0 / 25%) 0px 8px 24px;
}
.form-row {
  display: flex;
  width: 100%;
  margin-right: 16px;
  row-gap: 0px;
  margin-top: 12px;
}

.settings-form-input {
  padding: 8px 16px !important;
  color: #424242 !important;
  font-size: 18px !important;
  line-height: 23% !important;
  height: 100%;
}

.submitBtn {
  position: "absolute";
  bottom: 0;
  right: 24;
}

.form-row-right-field {
  margin-left: 16px;
  row-gap: 0;
}

.settings-tab-header {
  margin-left: 5px;
}
@media only screen and (max-width: 756px) {
  .settings-wrapper {
    border-radius: 25px;
    width: 100%;
    /* margin : 0px 16px; */
  }
  .settings-div {
    flex-direction: column;
  }
  .settings-tab {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    max-width: 100%;
    width: 100%;

    /* width: 100%;
    padding: 24px;
    max-width: 150px;
    min-width: 0px; */
  }
  .form-row {
    flex-direction: column;
  }
  .submitBtn {
    display: flex;
    justify-content: center;
  }
  .settings-right-inner-wrap h3 {
    text-align: center;
  }
  .form-row-right-field {
    margin-left: 0;
  }

  .settings-form-input {
    padding: 8px 16px !important;
    color: #424242 !important;
    font-size: 18px !important;
    line-height: 23% !important;
    height: 100%;
    border-radius: 10px;
  }
  h2,
  p {
    text-align: center;
  }
}

@media only screen and (max-width: 995px) {
  h2,
  p {
    text-align: center;
  }
}

@media only screen and (max-width: 300px) {
  .settings-tab {
    padding: 0px;
  }
  .settings-tab-header {
    margin-left: 0px;
  }
}
