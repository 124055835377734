.payment-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
}
.wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.payment-card {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px 8px 0 0;
  max-width: 100%;
  min-height: 136px;
  max-height: 400px;
  padding: 0 24px;
  margin-top: 32px;
  position: relative;
}

.payment-card-left-div {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 50%;
  flex-direction: column;
}
.payment-card-right-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.payment-card-right-div .right-div {
  display: flex;
}

.payment-detail-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  margin-top: 20px;
}

.payment-details {
  background: #f1fff2;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 16px 19px;
  width: 100%;
  max-width: 408px;
  min-width: 376px;
  display: flex;
  align-items: center;
  height: 100%;
  /* max-height: 80px; */
}

/* @media only screen and (max-width: 770px) {
  h3 {
    font-size: 14px;
  }
  p {
    font-size: 12px;
  }
} */

@media only screen and (max-width: 1009px) {
  .payment-card {
    /* height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #ffffff;
        box-shadow: 0px 1px 8px rgb(0 0 0 / 12%);
        border-radius: 8px 8px 0 0;
        max-width: 100%;
        min-height: 136px;
        max-height: 144px;
        padding: 0 24px;
        margin-top: 32px; */
    flex-direction: column;
    padding: 18px;
  }

  .payment-details {
    max-height: 68px;
    min-width: 0px;
  }

  .payment-card-right-div .right-div {
    display: flex;
    position: absolute;
    top: 10px;
  }
}
.payment-margin {
  margin-left: 16px;
}
@media only screen and (max-width: 768px) {
  .payment-wrapper{
    width: 95%;
  }
  .payment-card-left-div{
    width: 100%;
  }
}
@media only screen and (max-width: 420px) {
  .small-p {
    font-size: 12px;
  }
  .payment-details{
    flex-direction: column;
    max-height: max-content;
  }
}
@media only screen and (max-width: 290px) {
  .payment-card {
    padding: 5px;
  }
  .payment-details {
    padding: 5px;
  }
  .payment-margin {
    margin-left: 0px;
  }
}
