@import "~antd/dist/antd.css";
@import "./fonts.css";
@import "./form.css";
@import "./buttons.css";
@import "./table.css";
@import "./grid.css";
@import "./upgrade-styles.css";
@import "./index.css";
@import "./cards.css";
@import "./checkbox.css";
@import "~cropperjs/dist/cropper.css";

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::selection {
  color: #ffffff !important;
  background-color: #274b28 !important;
}

.App {
  min-height: 100%;
  width: 100%;
  overflow-x: hidden;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern";
}

html {
  scroll-behavior: smooth;
}

body {
  -webkit-appearance: none;
  font-family: CircularStd, sans-serif;
  overflow-x: hidden;
}

body,
.ant-card,
.ant-modal,
.ant-table {
  font-variant: none !important;
  font-feature-settings: "kren", "kern" !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 !important;
  font-weight: normal;
}

h1 {
  font-size: 32px;
  line-height: 40px;
}

h2 {
  font-size: 22px;
  line-height: 32px;
}

h3 {
  font-size: 18px;
  line-height: 24px;
}

h4 {
  font-size: 16px;
  line-height: 23px;
}

h5 {
  font-size: 14px;
  line-height: 20px;
}

h6 {
  font-size: 12px;
  line-height: 18px;
}

p {
  font-size: 16px;
  line-height: 20px;
  margin: 8px 0;
}

.w-100 {
  width: 100%;
}

.wv-100 {
  width: 100vw;
}

.wv-50 {
  width: 50vw;
}

.h-100 {
  height: 100%;
}

.hv-100 {
  height: 100vh;
}

.hv-50 {
  height: 50vh;
}

.mt-32 {
  margin: 32px 0 0;
}

.mt-24 {
  margin: 24px 0 0;
}

.mt-20 {
  margin: 20px 0 0;
}

.mt-16 {
  margin: 16px 0 0;
}

.mt-12 {
  margin: 12px 0 0;
}

.mt-8 {
  margin: 8px 0 0;
}

.padd-16 {
  padding: 16px;
}

.padd-14 {
  padding: 14px;
}

button {
  cursor: pointer !important;
}

span[aria-label="eye-invisible"],
span[aria-label="eye"] {
  color: #000000 !important;
}

.settings-form-item span[aria-label="eye-invisible"],
.settings-form-item span[aria-label="eye"] {
  color: rgba(1, 1, 1, 1) !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ant-switch-checked {
  background-color: #274b28 !important;
}

input[type="number"] {
  -moz-appearance: textfield;
}

span[aria-label="caret-up"],
span[aria-label="caret-down"] {
  color: #01010140 !important;
  font-size: 14px !important;
}

.borderEEE {
  border: 1px solid #eeeeee;
}

.br-4 {
  border-radius: 4px;
}

.br-8 {
  border-radius: 8px;
}

.br-12 {
  border-radius: 12px;
}

.br-16 {
  border-radius: 16px;
}

/* General styles */

.flex {
  display: flex;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.justifyStart {
  justify-content: flex-start;
}

.justifyCenter {
  justify-content: center;
}

.justifyBetween {
  justify-content: space-between !important;
}

.justifyEnd {
  justify-content: flex-end;
}

.alignStart {
  align-items: flex-start;
}

.alignCenter {
  align-items: center;
}

.alignEnd {
  align-items: flex-end;
}

.textGray {
  color: #757575;
}

.textGreen {
  color: #274b28;
}

.active {
  color: #2196f3;
}

/* Cropper */

.cropper-modal {
  background: #274b28 !important;
  opacity: 0.5 !important;
  border-radius: 8px !important;
}

/* Buy now */

.buyNow {
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #f1fff2;
  border: 1px solid #274b28;
  border-radius: 100px;
  width: 100%;
  text-align: center;
  cursor: pointer;
}

.cartIcon {
  margin-left: 16px;
}

.success-toast {
  background-color: #27ae60;
}

.error-toast {
  background-color: #eb5757;
}
.wspace_4 {
  word-spacing: -4px !important;
}

.ant-layout-sider-zero-width-trigger {
  position: absolute;
  top: 10px;
  right: -57px;
  z-index: 1;
  width: 50px;
  height: 50px;
  color: #274b28;
  font-size: 29px;
  line-height: 42px;
  text-align: center;
  background: transparent;
  border-radius: 100%;
  cursor: pointer;
  transition: background 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ant-layout-header {
  background: #274b28;
}
