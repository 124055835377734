.card-expiry-div {
  display: flex;
  width: 100%;
  justify-content: space-between;
  /* flex-wrap: wrap; */
}

.card-expiry-div .textfield {
  height: 64px;
  color: #424242;
  background: #fafafa;
  border-radius: 8px;
  border: 1px solid #e0e0e0;
  font-size: 18px;
  line-height: 23px;
  box-shadow: none;
  margin: 10px 0 0;
  padding: 0px 0px 0px 16px;
  width: 154px;
}

.item {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0;
  margin: 0 24px;
  border: none;
}

.bottom-div-wrapper {
  margin-top: 24px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.cancel-field {
  background: #f5f5f5;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  height:46px;
  font-size: 20px  !important;
  cursor: pointer;
  padding: 10px 32px 8px;
  margin-right: 16px;
}

.submit-field {
  color:"#fff" !important;
  position: relative;
  z-index: 1;
  border: 0px solid #274b28;
  background-color: #274b28;
}
@media screen and (max-width: 558px) {
  .card-expiry-div {
    display: flex;
    flex-direction: column;
  }
  .textfield {
    width: 100% !important;
  }
  .item {
    margin: 0;
  }
  /* .cancel-field {
    padding: 10px 12px 8px;
  } */
}
