.patch-detail-outer-div {
  width: 100%;
  margin: "96px auto 0";
}

.map {
  width: 100%;
  /* height:400px; */
}

.patch-bottom-row2 {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  width: 100%;
  margin: 48px 0px;

}

.patch-bottom-row2-div1 {
  display: flex;
  flex-direction: column;
  width: 45%;
  margin-left: 32px;
}

.patch-map {
  width: 390px;
  height: 500px;
}

.patch-bottom-row2-div2 {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-left: 32px;
  width: 45%;
}
.map_facts{
  display: flex;
  width: 100%;
  margin: 40px 0px;
}
.facts{
  width: 48%;
  margin-right: 32px;
  display: flex;
  flex-direction: column;
}
.maps{
  width: 48%;
}

@media screen and (max-width: 768px) {
  .patch-bottom-row2-div1{
    width: 100%;
    margin: 0px;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    margin-left:0px; */
  }
  /* .map{
    width:80%;
    height:100px;
  } */
  .map_facts{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .maps{
    width: 90%;
  }
  .facts{
    width: 90%;
    margin-right:0px;
    margin-bottom: 24px;
  }

  .facts .textGray{
    text-align: center;
  }
 

  .patch-bottom-row2-div2 {
    width: 100%;
    margin:0px;
    margin-top:16px;
  }
  .patch-bottom-row2{
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 0px;
  }
}