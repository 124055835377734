.update-form-item {
    width: 100%;
    margin: 0;
    padding: 10px 0 21px;
}

.update-form input {
    background: #FAFAFA !important;
    border: 1px solid #E0E0E0 !important;
    border-radius: 8px;
    height: 56px;
    width: 415px;
    margin-left: 16px;
    padding-left: 24px !important;
    color: #424242 !important;
    font-size: 20px !important;
    line-height: 25px !important;
}

.update-form-select>div {
    background: #FAFAFA !important;
    border: 1px solid #E0E0E0 !important;
    border-radius: 8px !important;
    width: 96px !important;
    height: 56px !important;
    padding: 8px 12px !important;
}

.new-payment-form-select>div {
    background: #FAFAFA !important;
    border: 1px solid #E0E0E0 !important;
    border-radius: 8px !important;
    width: 100% !important;
    height: 56px !important;
    padding: 8px 12px !important;
}

.update-form .ant-select-arrow {
    top: 65% !important;
    right: 18px !important;
}

.settings-form-item {
    width: 100%;
    margin: 0;
    padding: 10px 0 21px;
}

.settings-label {
    color: #9E9E9E !important;
    font-size: 13px !important;
    line-height: 18px !important;
    word-spacing: -4px !important;
}

.settings-label span {
    color: #ff0000;
    font-size: 12px;
    line-height: 16px;
    word-spacing: -4px;
}

.settings-main input, .settings-main input[type="password"] {
    padding: 8px 16px !important;
    color: #424242 !important;
    font-size: 18px !important;
    line-height: 23% !important;
}

.active-icon path {
    fill: #274B28 !important;
}

input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
}

.ant-input-affix-wrapper.ant-input-password>.ant-input#password, .ant-input-affix-wrapper.ant-input-password>.ant-input#confirmPassword {
    background: transparent !important;
}

.password .ant-input-suffix {
    margin-top: 16px !important;
}