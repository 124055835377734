.card-view {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  background: #ffffff;
  box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 24px;
}

.large-card-view {
  width: 100%;
  /* max-height: 378px; */
  /* max-width: 454px; */
  margin-top: 15px;
}

.card-view:nth-child(1) {
  margin-right: 24px;
}

.small-card-view {
  width: 100%;
  max-height: 378px;
  max-width: 454px;
}

.card-outer-div {
  display: flex;
  width: 100%;
  margin-top: 16px;
  flex-direction: row;
}

.card-outer-div .card-inner-div-1 {
  width: 100%;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  margin-right: 16px !important;
  min-width: 0px !important;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.card-outer-div .card-iner-div-2 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: #5bcd63;
  min-width: 0px;
  width: 100%;
  padding: 24px 24px 16px;
}

.col3-div-1 {
  display: flex;
  justify-content: space-between !important;
  width: 100%;
}
.temp-p {
  font-size: 12px;
}

.temperature-div {
  display: flex;
  width: 100%;
  margin: 16px 0px;
}
.temp-icon-wrapper {
  background: rgb(227, 242, 253);
  border-radius: 8px;
  min-width: 0px;
  padding: 0px;
  /* margin-top: 23px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}

@media only screen and (max-width: 375px) {
  .temperature-div {
    flex-direction: column;
  }
  .temp-icon-wrapper {
    margin-top: 23px;
  }
}

@media only screen and (max-width: 500px) {
  .temp-p {
    font-size: 10px;
  }
}

@media only screen and (max-width: 450px) {
  .card-view {
    padding: 8px;
  }
}

@media only screen and (max-width: 350px) {
  .card-iner-div-2 {
    padding: 5px !important;
  }
}

/* making cards responsive */

.card-margin {
  margin-left: 16px;
}
.card-padding {
  padding: 12px;
}

@media only screen and (max-width: 336px) {
  .card-margin {
    margin-left: 2px;
  }
  .card-padding {
    padding: 0px;
  }
}

/* temp card */

.temp-img {
  height: 120px;
  width: 120px;
}
.chart-img {
  width: 207px;
  height: 127px;
}

.water-img {
  height: 72px;
  width: 212px;
  overflow-x: hidden;
}
.humidity-img {
  height: 154px;
  width: 154px;
}

@media only screen and (max-width: 390px) {
  .temp-img {
    height: 50px;
    width: 50px;
  }
}

@media only screen and (max-width: 420px) {
  .water-img {
    height: 72px;
    width: 136px;
  }
}

.water-img-container {
  background: rgb(33, 150, 243);
  border-radius: 8px;
  max-width: 175px;
  height: 288px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding:  40px 24px;
}

@media only screen and (max-width: 400px) {
  .water-img-container {
    padding: 24px 0px;
  }
  .water-img {
    height: 72px;
    width: 100px;
  }
  .humidity-img {
    height: 154px;
    width: 122px;
  }
}

.selectorBtn {
  background: rgb(255, 255, 255);
  display: flex;
  align-items: center;
  width: auto;
  border-radius: 100px;
  box-shadow: rgb(0 0 0 / 12%) 0px 2px 8px;
  padding: 8px 24px;
  cursor: pointer;
}

@media only screen and (max-width: 390px) {
  .selectorBtn {
    padding: 3px 6px;
  }
  .selectorBtn h4 {
    font-size: 10px;
  }
  .statisticsLable {
    margin: 0px !important;
    font-size: 10px;

    margin-left: 4px !important;
  }
}
