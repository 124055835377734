.row {
    display: grid;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    width: 100%;
    grid-template-columns: 25% 50% 25%
}

.col {
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.12);
    border-radius: 12px;
}

.col {
    background: #ffffff;
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
}

@media (min-width: 1920px) {
    .col {
        width: calc(100%/5) !important;
        max-width: 425px !important;
        min-width: 424px !important;
    }
}

@media (min-width: 1600px) and (max-width: 1919px) {
    .col {
        width: calc(100%/4) !important;
        max-width: 425px !important;
        min-width: 424px !important;
    }
}

@media (min-width: 1367px) and (max-width: 1599px) {
    .col {
        /* width: calc(100%/3) !important; */
        max-width: 389px !important;
        /* min-width: 388px !important; */
    }
}

@media (min-width:992px) {
    .row {
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 24px;
    }
}

@media (min-width:992px) and (max-width: 1366px) {
    .col {
        width: 100%;
        max-width: 327px !important;
    }
}

@media (min-width:768px) and (max-width: 991px) {
    .row {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 24px;
    }
    
}
@media screen and (max-width: 767px) {
    .col {
        max-width: 100%;
        margin: 12px 24px;

    }
}
    
