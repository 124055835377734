/* Dashboard  */

.statisticsLable {
    font-size: 18px;
    line-height: 26px;
    color: #757575;
    margin: 8px 0 0 !important;
}
.cardTitle{
    font-size: 16px;
    line-height: 20px;
    color: #101112;
}

/* .content-body{
    background-color: red;
    overflow-y: hidden;
}
.content-body::-webkit-scrollbar{
    display: none;
} */


.cardDescription{
    font-size: 14px;
    line-height: 18px;
    color: #757575;
    margin: 4px 0 0 !important;
}

@media only screen and (max-width: 768px) {
   .statisticsLable{
    padding-left: 16px;
   }
   .cardDescription{
    max-width: 185px;
    text-align: left;
   }
  }