.history-table {
  border-collapse: collapse;
  width: 100%;
}

.history-table td,
.history-table th {
  border: none;
}

.history-table tr:hover {
  background-color: #a9f1ab;
}

.history-table th {
  text-align: left;
  background-color: #274b28;
  color: white;
  font-size: 16px;
  line-height: 20px;
  padding: 16px 0px 16px 24px;
}

.history-table td {
  padding: 12px 0px 12px 24px;
}

.modal-width {
  width: 900px !important;
}

.modal-header-div {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 24px;
}

.modal-header-div1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

@media screen and (max-width: 540px) {
  .history-table th {
    padding: 5px;
  }
}

@media screen and (max-width: 674px) {
  .history-table td {
    padding: 5px;
  }
  .history-table td h4 {
    font-size: 14px;
  }

  .history-table td h5 {
    font-size: 12px;
  }
  .history-table th {
    font-size: 10px;
  }
  .modal-header-div {
    padding: 10px;
  }
}

@media screen and (max-width: 318px) {
  .history-table th {
    padding: 0px;
  }

  .history-table td {
    padding: 0px;
  }
  .modal-header-div {
    padding: 5px;
  }
}
@media screen and (max-width: 318px) {
  .history-table th {
    padding: 1px;
    font-size: 8px;
  }
}
